.badge-text {
  width: 55px;
  height: 55px;
  font-size: 17px;
  font-weight: 600;
  color: var(--white-01-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.red-badge{
    background: var(--red-dark-01-color);

}

.teal-badge{
    background:var(--main-01-color) ;
}
