/* ====================================
START BASIC INFORMATION
=========================================*/
.basic-information-content
  .all-basic-info
  :is(.header-info-profile, .bottom-basic-info),
.settings-profile .all-settings-profile {
  border: var(--border-06);
  border-radius: 16px;
  padding: 22px;
  position: relative;
}
.icon-edit-pen,
.icon-style-01 {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white-02-color);
  border: var(--border-06);
  border-radius: 8px;

  cursor: pointer;
  transition: all 0.5s;
}

.basic-information-content .all-basic-info .icon-edit-pen {
  position: absolute;
  left: 15px;
  bottom: 15px;
}


.icon-edit-pen:hover,
.icon-style-01:hover {
  border-color: var(--main-01-color);
}
.basic-information-content
  .all-basic-info
  .header-info-profile
  .main-info-one
  :is(.title, .text),
.basic-information-content .all-basic-info .bottom-basic-info .title {
  font-size: 20px;
  font-weight: 600;
  color: var(--gray-01-color);
}
.basic-information-content
  .all-basic-info
  .header-info-profile
  .main-info-one
  .text {
  color: var(--dark-01-color);
}
.basic-information-content .all-info-content .main-info-one {
  width: 25% !important;
  margin-bottom: 15px;
}
.basic-information-content .all-basic-info .main-btn:hover :is(svg path, i) {
  fill: var(--white-01-color);
}

/* ====================================
END BASIC INFORMATION
=========================================*/

/* ======================================
START TAB ADDRESS
===========================================*/
.address-profile .all-address-profile .cards-address .card-address-one {
  border: var(--border-06);
  padding: 15px;
  border-radius: 16px;
  transition: all 0.5s;
}
.address-profile .all-address-profile .cards-address .card-address-one:hover {
  border-color: var(--main-01-color);
}
.address-profile
  .all-address-profile
  .cards-address
  .card-address-one
  .icon-edit-pen {
  position: relative;
  bottom: initial;
  left: initial;
}
.address-profile .all-address-profile .cards-address .card-address-one .title,
.settings-profile .all-settings-profile .setting-one .text-settings .title {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-01-color);
  padding-bottom: 10px !important;
}
.address-profile .all-address-profile .cards-address .card-address-one .text,
.settings-profile .all-settings-profile .setting-one .text-settings .text {
  font-size: 16px;
  font-weight: 500;
  color: var(--gray-01-color);
  line-height: 1.8;
}

/* ======================================
END TAB ADDRESS
===========================================*/
/* ========================================
START TAB SETTINGS 
===================================*/
.settings-profile
  .all-settings-profile
  .setting-one
  .form-switch
  .form-check-input {
  width: 3em !important;
  cursor: pointer;
}
.settings-profile .button-remove-account .main-btn {
  border-color: var(--red-dark-01-color);
  color: var(--red-dark-01-color);
}

.settings-profile .button-remove-account .main-btn:hover {
  background: var(--red-dark-01-color);
  color: var(--white-01-color);
}
.settings-profile .button-remove-account .main-btn:hover :is(svg path, i) {
  color: var(--white-01-color);
  fill: var(--white-01-color);
}
/* ========================================
END TAB SETTINGS 
===================================*/

/* ==============
SCREEN 991px
==============*/

@media screen and (max-width: 991px) {
  .basic-information-content .all-info-content .main-info-one {
    width: 40% !important;
  }
}

/* ==============
SCREEN 767px
==============*/

@media screen and (max-width: 767px) {
  .basic-information-content .all-info-content .main-info-one {
    width: 100% !important;
  }
  .basic-information-content
    .all-basic-info
    .header-info-profile
    .main-info-one
    :is(.title, .text) {
    font-size: 16px;
  }
}

/* ==============
SCREEN 522px
==============*/

@media screen and (max-width: 575px) {
  .profile-page-info .tabs-profile {
    gap: 10px;
  }
  .profile-page-info .tabs-profile .nav-link {
    font-size: 14px;
  }
  .all-basic-info .icon-edit-pen {
    bottom: initial;
    top: 15px;
  }
  .profile-page-info .all-info-profile-content {
    padding: 50px 15px !important;
  }

  .address-profile .all-address-profile .cards-address .card-address-one .text {
    font-size: 13px;
  }
}

/* ==============
SCREEN 401px
==============*/

@media screen and (max-width: 401px) {
  .basic-information-content
    .all-basic-info
    .header-info-profile
    .main-info-one
    :is(.text) {
    font-size: 12px;
    font-weight: 700;
  }
  .profile-page-info .tabs-profile {
    gap: 0px;
  }
  .profile-page-info .tabs-profile .nav-link {
    font-size: 13px;
    font-weight: 700;
  }
  .address-profile .all-address-profile .cards-address .card-address-one {
    flex-wrap: wrap;
    gap: 15px;
  }
  .address-profile .header-address .main-btn {
    font-size: 13px;
    font-weight: 700;
  }
  .settings-profile .setting-one {
    flex-wrap: wrap-reverse;
  }
  .settings-profile .all-settings-profile .setting-one .text-settings .title {
    font-size: 15px;
    font-weight: 700;
  }
  .settings-profile .all-settings-profile .setting-one .text-settings .text {
    font-size: 13px;
  }
}

/* ==============
SCREEN 340px
==============*/

@media screen and (max-width: 340px) {
  .basic-information-content
    .all-basic-info
    .header-info-profile
    .main-info-one
    :is(.title) {
    font-size: 18px;
  }
  .basic-information-content
    .all-basic-info
    .header-info-profile
    .main-info-one
    :is(.text) {
    font-size: 14px;
  }
  .basic-information-content
    .all-basic-info
    .header-info-profile
    .all-info-content {
    flex-direction: column;
  }
}


.select-type .form-select{
  padding: 10px;
}