.banner-cards-about .main-banner-cards {
  background: var(--main-01-color);
  padding: 50px 0;
}

.banner-cards-about .all-cards-about .card-about-one {
  border: var(--border-05);
  border-radius: 16px;
  padding: 30px;
  text-align: center;
  transition: all 0.5s;
}
.banner-cards-about
  .all-cards-about
  .card-about-one
  :is(.icon-card, .title, .text) {
  transition: all 0.5s;
}
.banner-cards-about .all-cards-about .card-about-one .title {
  font-size: 20px;
  font-weight: 600;
  color: var(--white-01-color);
}

.banner-cards-about .all-cards-about .card-about-one .text {
  font-size: 17px;
  font-weight: 500;
  color: var(--white-02-color);
  line-height: 1.8;
}

.banner-cards-about .all-cards-about .card-about-one .icon-card {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 166px;
  height: 166px;
  margin: auto;
}

.banner-cards-about
  .all-cards-about
  .card-col:nth-child(1)
  .card-about-one:hover
  .icon-card {
  background-image: url("../../../assets/images/aboutIcons/1.svg") !important;
}
.banner-cards-about
  .all-cards-about
  .card-col:nth-child(2)
  .card-about-one:hover
  .icon-card {
  background-image: url("../../../assets/images/aboutIcons/2.svg") !important;
}
.banner-cards-about
  .all-cards-about
  .card-col:nth-child(3)
  .card-about-one:hover
  .icon-card {
  background-image: url("../../../assets/images/aboutIcons/3.svg") !important;
}

.banner-cards-about .all-cards-about .card-about-one:hover {
  background: var(--white-01-color);
}

.banner-cards-about .all-cards-about .card-about-one:hover :is(.title, .text) {
  color: var(--main-01-color);
}

@media screen and (max-width:450px) {
  .banner-cards-about .all-cards-about .card-about-one {
    padding: 30px 15px;
  }
}