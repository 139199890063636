.main-btn-1-1 {
  padding: 12px 22px;
  background: var(--main-01-color);
  color: var(--white-01-color);
  font-size: 17px;
  font-weight: 600;
  border-radius: 8px;
  border: var(--border-01);
  border-width: 1.5px;
  border-color: var(--main-01-color);
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  text-align: center;
  gap: 10px;
}

.main-btn-1-1:hover,
.main-btn-1-1:focus {
  background: var(--main-01-color);
  border-color: var(--main-01-color);
}

.menu-drop-1,
.menu-drop-1 .btn.show,
.menu-drop-1 .btn:active {
  border: none;
}
.menu-drop-1 .dropdown-toggle::after {
  display: none;
}

.menu-drop-1 .dropdown-item {
  font-size: 17px;
  font-weight: 600;
  color: var(--dark-01-color);
  text-align: center;
  padding: 15px;
}

.menu-drop-1 .dropdown-menu {
  padding: 0;
}

.menu-drop-1 .dropdown-item:not(:last-child) {
  border-bottom: var(--border-03);
}
.menu-drop-1 .dropdown-menu.show {
  transform: translate(104px, 0px) !important;
}

@media screen and (max-width: 575px) {
  .main-btn-1-1 {
    margin-top: 15px;
  }
}
@media screen and (max-width: 450px) {
  .left-info-page-content .dropdown,
  .main-btn-1-1,
  .left-info-page-content .dropdown-menu {
    width: 100% !important;
  }
}

.css-wsp0cs-MultiValueGeneric {
  display: flex;
}

.drop-menu-head {
  padding: 0;
  --bs-dropdown-min-width: 9.4rem;
}

.drop-menu-head .dropdown-item {
  padding: 12px;
}

.drop-menu-head .dropdown-item:not(:last-child) {
  border-bottom: var(--border-06);
}

.custom-tabs {
  border-bottom: 2px solid #eee;
}

.custom-tabs .nav-link {
  color: #666;
  border: none;
  padding: 0.5rem 1.5rem;
  margin-right: 1rem;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease;
}

.custom-tabs .nav-link:hover {
  color: #333;
}

.custom-tabs .nav-link.active {
  color: #007bff;
  border-bottom: 2px solid #007bff;
  background: transparent;
}
