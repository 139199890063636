.header-about .all-header-about .content-info-about .title {
  font-size: 27px;
  font-weight: 600;
  color: var(--dark-01-color);
  line-height: 1.8;
}
.header-about .all-header-about .content-info-about .text {
  font-size: 17px;
  font-weight: 500;
  color: var(--dark-03-color);
  line-height: 1.8;
  padding-top: 15px !important;
}

@media screen and (max-width: 450px) {
  .header-about .all-header-about .content-info-about .text {
    font-size: 14px;
  }
}
