.filter-content .header-filter .num-results-filter {
  font-size: 25px;
  font-weight: 600;
  color: var(--dark-01-color);
}

.filter-content .card-filter-one .img-slider img {
  border-radius: 16px;
}
.filter-content .card-filter-one .swiper-button-prev,
.filter-content .card-filter-one .swiper-button-next {
  transition: all 0.5s;
}
.filter-content .card-filter-one .swiper-button-prev {
  margin-right: -150px;
}
.filter-content .card-filter-one:hover .swiper-button-prev {
  margin-right: -15px;
}

.filter-content .card-filter-one .swiper-button-next {
  margin-left: -150px;
}
.filter-content .card-filter-one:hover .swiper-button-next {
  margin-left: 27px;
}

.filter-content .card-filter-one .header-card {
  border-bottom: var(--border-06);
  padding: 15px 0;
}
.filter-content .card-filter-one :is(.title, .num-price) {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-01-color);
}
.filter-content .card-filter-one :is(.info-time, .main-text-info) {
  padding-top: 10px;
}
.filter-content
  .card-filter-one
  :is(.text-info, .rate-num, .total-info-rate, .text-time) {
  font-size: 16px;
  font-weight: 500;
  color: var(--gray-01-color) !important;
}
.filter-content .card-filter-one .bottom-card {
  padding-top: 15px;
}

/* SCREEN 991PX */
@media screen and (max-width: 991px) {
  .filter-content .card-filter-one .img-slider img {
    height: 235px !important;
  }
}
/* SCREEN 767PX */
@media screen and (max-width: 767px) {
  .map-info .iframe-map {
    height: 400px;
  }
  .filter-content .card-filter-one .img-slider img {
    height: 276px !important;
  }
}
/* SCREEN 450PX */
@media screen and (max-width: 450px) {
  .map-info .iframe-map {
    height: 280px;
  }
}
