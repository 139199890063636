.all-team-info {
  padding-bottom: 70px;
}
.all-team-info .header-title .title {
  font-size: 25px;
  font-weight: 600;
  color: var(--dark-01-color);
  padding-bottom: 25px !important;
}
.team-info-one {
  width: fit-content;
  cursor: pointer;
  margin-left: 30px;
}

.team-info-one .img-employee img {
  border-radius: 50%;
  width: 85px;
  height: 85px;
  border: var(--border-06);
  border-width: 4px;
  border-color: #fff;
}

.team-info-one .info-text {
  padding-top: 8px;
}
.team-info-one .info-text .title {
  font-size: 17px;
  font-weight: 500;
  color: var(--dark-01-color);
}

.team-info-one .info-text .text-title {
  font-size: 15px;
  font-weight: 500;
  color: var(--gray-01-color);
}

@media screen and (max-width: 575px) {
  .team-info-one {
    margin-left: 15px;
  }
}

@media screen and (max-width: 471px) {
  .main-team-info {
    gap: 30px !important;
  }
  .team-info-one {
    margin-left: 0px;
  }
}

@media screen and (max-width: 340px) {
  .main-team-info {
    gap: 15px !important;
  }
  .team-info-one {
    border: var(--border-06);
    border-radius: 16px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
  }
}
