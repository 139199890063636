.left-info-search .form-search {
  width: 300px;
}

@media screen and (max-width: 575px) {
  .header-page-info-top
    :is(.left-info-page-content, .left-info-search, .form-search) {
    width: 100%;
  }
.header-page-w .left-info-page-content
{
  width: auto ;
}

  .header-page-info-top .left-info-search {
    display: block !important;
  }
  .header-page-info-top .left-info-search .main-btn {
    margin-top: 15px;
  }
}

@media screen and (max-width: 450px) {
  .header-page-info-top .left-info-search .main-btn {
    width: 100%;
  }
}
