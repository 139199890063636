.select-booking {
  width: 330px;
}
.select-booking .form-select {
  padding: 12px 15px;
  font-size: 17px;
  font-weight: 600;
}

@media screen and (max-width: 455px) {
  .select-booking {
    width: 100%;
  }
  .select-booking .form-select {
    padding: 12px 12px 12px 130px;
  }
}

@media screen and (max-width: 340px) {
  .select-booking .form-select {
    padding: 12px 12px 12px 100px;
  }
}
@media screen and (max-width: 290px) {
  .select-booking .form-select {
    padding: 12px 12px 12px 80px;
  }
}
