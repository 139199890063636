.update-profile-data {
  padding: 2rem 0;
  background-color: var(--background-color);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.profile-update-content {
  display: flex;
  flex-direction: row-reverse;
  gap: 3rem;
  margin-top: 2rem;
  align-items: flex-start;
}

.image-update-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white-01-color);
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.profile-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1.5rem;
  border: 4px solid var(--primary-color);
}

.update-image-btn {
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  background-color: rgb(216, 215, 222);
  cursor: pointer;
  transition: background-color 0.6s;
  font-weight: 800;
  font-size: 1rem;
  margin-top: 1rem;
  box-shadow: 0 4px 6px rgba(67, 9, 9, 0.1);
}

.update-image-btn:hover {
  background-color: rgb(166, 166, 170);
}

.form-profile-update {
  flex: 2;
  background-color: var(--white-01-color);
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 991px) {
  .profile-update-content {
    flex-direction: column;
  }

  .image-update-section,
  .form-profile-update {
    width: 100%;
  }
}

/* Existing styles for form elements */
.select-country .ReactFlagsSelect-module_selectValue__152eS {
  font-size: 17px;
  font-weight: 600;
  color: var(--gray-01-color);
}

.select-country .ReactFlagsSelect-module_selectOptionValue__vS99-,
.select-country .ReactFlagsSelect-module_selectValue__152eS {
  gap: 10px;
}

.select-phone-number input {
  width: 100% !important;
  padding: 24px 0px 24px 80px !important;
  text-align: left !important;
  font-size: 17px;
  font-weight: 600;
  color: var(--dark-01-color);
}

.select-phone-number .react-tel-input .flag-dropdown {
  padding: 0 15px;
}

.select-phone-number .react-tel-input .selected-flag:hover,
.select-phone-number .react-tel-input .selected-flag:focus {
  background: transparent !important;
}

/* Add these styles to your existing CSS file */

.form-container {
  max-width: 100%;
}

.form-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.form-row > div {
  flex: 1;
}

.form-label {
  font-weight: 600;
  margin-bottom: 0.5rem;
  display: block;
}

.form-select,
.form-control {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background-color: var(--input-background);
}
