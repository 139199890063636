.all-complete-account .header-page-info-top {
  flex-direction: column;
  justify-content: initial !important;
  align-items: flex-start !important;
}
.all-complete-account .header-page-info-top .title-text-1 {
  font-size: 27px;
}
.all-complete-account .header-page-info-top .title-page {
  font-size: 18px;
  font-weight: 600;
  color: var(--gray-01-color);
}
.all-data-com .filepond--file-action-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.all-data-com .filepond--drop-label {
  position: relative;
}
.all-data-com .filepond--drop-label:before {
  font-family: "Font Awesome 6 Free";
  content: "\f093";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}

.all-data-com
  .filepond--wrapper
  .filepond--drop-label.filepond--drop-label
  label {
  font-weight: 600;
  color: var(--gray-01-color);
}

.all-data-com .filepond--wrapper .filepond--root .filepond--drop-label {
  background: var(--white-03-color) !important;
}

.all-data-com .filepond--wrapper {
  background: var(--white-03-color);
  border: 2px dotted var(--gray-01-color);
  margin-bottom: 25px;
}
.all-data-com {
  border-width: 2px !important;
  margin: 25px 0 50px 0;
}
.all-data-com .filepond--root .filepond--drop-label {
  min-height: 5.75em;
}

.input-clock .react-datepicker-wrapper {
  display: block !important;
  width: 100%;
}

.input-clockm .react-datepicker__time-container .react-datepicker__time {
  background-color: #ffffff !important;
}
.input-clock
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background-color: var(--main-01-color) !important;
  color: var(--white-01-color) !important;
}

.input-clock
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: var(--main-01-color) !important;
  color: var(--white-01-color) !important;
}

.input-clock .react-datepicker__header {
  background: var(--white-03-color);
}
.input-clock
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list::-webkit-scrollbar {
  display: none;
}
.input-clock
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  .react-datepicker__time-list-item {
  font-weight: 600;
  color: var(--dark-01-color);
}
.input-clock
  .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}

.input-clock .input-group-text {
  color: var(--gray-01-color);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 10px 15px;
}

.all-data-com :is(.input-group, .input-clock) {
  display: block !important;
}

.all-data-com :is(.form-group, .select-reg) {
  margin-top: 16.5px;
}

/* START ADD NEW EMPLOYEE */
.all-add-employee {
  padding: 100px 0;
}

.all-add-employee .title-head {
  font-size: 22px;
  font-weight: 600;
  color: var(--dark-01-color);
}

/* END ADD NEW EMPLOYEE */
