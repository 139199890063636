.butons-filter-plans {
  background: var(--white-01-color);
  padding: 17px 35px;
  box-shadow: 0 5px 10px #eeeeee;
  width: fit-content;
  margin: auto;
  border-radius: 12px;
  border: var(--border-03);
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
}
.butons-filter-plans .button-plan {
  padding: 10px 25px;
  border-radius: 8px;
  background: transparent;
  border: var(--border-03);
  border-color: #fff;
  font-size: 17px;
  font-weight: 600;
  color: var(--dark-01-color);
}

.butons-filter-plans .button-plan.active {
  background: var(--main-01-color);
  color: var(--white-01-color);
 
}
.main-plans .title-top-plan{
    font-size: 22px;
    padding-right: 15px !important;
    font-weight: 600;
    color: var(--dark-01-color);
    padding-bottom: 22px !important;
}