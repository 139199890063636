.notice-card {
  border: var(--border-05);
  background: var(--white-01-color);
  padding: 15px;
  border-radius: 8px;
  transition: all 0.5s;
}
.notice-card:hover {
  border-color: var(--main-01-color);
}

.notice-card .icon-bell {
  width: 60px;
  height: 60px;
  background: var(--white-03-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border: var(--border-05);
  border-radius: 8px;
}
.notice-card .title {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-01-color);
  padding-bottom: 12px !important;
}
.notice-card :is(.text-link, .info-text-date) {
  font-size: 17px;
  font-weight: 600;
  color: var(--gray-01-color);
  transition: all 0.5s;
}

.notice-card .text-link:hover {
  color: var(--main-01-color);
}

.notice-card .icon-bell .icon-img {
  width: 30px;
  height: 30px;
}

.notice-card .icon-bell .icon-img {
  width: 30px;
  height: 30px;
}
.notice-card .icon-bell {
  transition: all 0.5s;
}
.col-card:hover .icon-bell {
  background: var(--main-01-color);
  border-color: var(--main-01-color);
}
.col-card:hover .icon-bell .icon-img {
  background-image: url("../../../assets/images/icons/bellWhite.svg") !important;
}

@media screen and (max-width: 767px) {
  .notice-card .text-link {
    font-size: 14px;
  }
}

@media screen and (max-width: 520px) {
  .notice-card .right-card-info {
    flex-wrap: wrap;
  }
}
