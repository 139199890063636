.card-product {
  border: var(--border-02);
  border-radius: 16px;
  border-color: var(--gray-02-color);
  transition: all 0.5s;
  position: relative;
}

.card-product:hover {
  border-color: var(--main-01-color);
  box-shadow: 0 0 10px var(--main-01-color);
  transform: translateY(-3px);
  z-index: 10;
  cursor: pointer;
}

.card-product .badge-product,
.card-product .icon-favorite {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1;
}

.card-product .icon-favorite {
  left: 25px;
  font-size: 25px;
  color: var(--white-01-color);
  cursor: pointer;
}
.card-product .icon-favorite.active {
  color: var(--main-01-color);
}
.card-product .img-card {
  position: relative;
}

.card-product .img-card::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.233);
  top: 0;
  right: 0;
  border-radius: 16px 16px 0 0;
}
.card-product .img-card img {
  border-radius: 16px 16px 0 0;
  height: 183.31px !important;
}

.card-product .card-body {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.card-product .card-body .card-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-02-color);
}

.card-product .card-body .main-text-info :is(.text-info, .rate-num, .total-info-rate) {
  font-size: 17px;
  font-weight: 600;
  color: var(--gray-01-color) !important;
}

.card-product .card-body .main-btn {
  border-color: var(--gray-02-color);
  border-radius: 0;
  border-width: 1.5px;
  padding: 6px 20px;
}
@media screen and (max-width: 575px) {
  .card-product .img-card img {
    height: 100% !important;
  }
}
