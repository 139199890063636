/* START HEADER TOP */
.header-top-content .left-info-search .form-search {
  width: 213px;
}
.header-top-content select {
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 12px 1rem 12px 4rem;
}

@media screen and (max-width: 809px) {
  .header-top-content .left-info-search .form-search {
    width: 500px;
  }
  .header-top-content :is(.select-one) {
    width: 352px;
  }
}

@media screen and (max-width: 751px) {
  .main-header-employee .left-info-page-content {
    width: 100%;
  }
  .header-top-content {
    display: block !important;
    margin-top: 15px !important;
  }
  .header-top-content :is(.left-info-page-content, .left-info-search, .form-search) {
    width: 100% !important;
  }
  .header-page-info-top .left-info-search {
    display: block !important;
  }
  .header-top-content :is(.select-one) {
    width: 100%;
    margin-bottom: 15px;
  }

  .header-top-content .main-btn {
    margin-top: 15px;
  }
}

/* END HEADER TOP */
/* START CONTANT INFO */

.basic-information-content .all-data-content .all-info-content .main-info-one {
  width: 100% !important;
}
/* START CONTENT INFO  */
