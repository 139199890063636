.navbar-menu-dash {
  background: var(--white-01-color);
  padding: 12px 16px;
  border-bottom: var(--border-03);
}

.navbar-menu-dash .toggle-menu {
  border: var(--border-03);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}

.info-menu-left .button-shop {
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 50%;
}

.info-menu-left .button-shop.active {
  background: var(--main-01-color);
  color: var(--white-01-color);
}
