.input-date-info {
  position: relative;
  width: 140px;
}

.input-date-info .input-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  color: var(--gray-01-color);
}

.input-date-info .react-datepicker__month-container {
  background: var(--white-01-color);
}

.input-date-info .react-datepicker__header {
  padding: 15px 0;
}

.input-date-info .react-datepicker {
  border-color: #f0f0f0 !important;
}

.input-date-info .react-datepicker__header {
  border-color: #d9d7d7 !important;
}

.input-date-info .react-datepicker__navigation {
  top: 23px !important;
}

.input-date-info .react-datepicker__day--keyboard-selected {
  background: var(--main-01-color) !important;
  color: var(--white-01-color) !important;
}

@media screen and (max-width: 437px) {
  .input-date-info,
  .input-date-info .react-datepicker-wrapper,
  .info-content-head-stat :is(.select-box-option, .form-select-lg, .main-btn) {
    width: 100%;
  }
  .table-content .table {
    width: 600px;
  }
}

/* START bottom-pagination */
.bottom-pagination .select-items .title-items,
.bottom-pagination .text-items,
.bottom-pagination .page-add-icon {
  font-size: 17px;
  font-weight: 600;
  color: var(--gray-01-color);
  display: flex;
  align-items: center;
}
.bottom-pagination .select-items select {
  width: 100px;
}
.bottom-pagination .page-add-icon {
  cursor: pointer;
}
/* END bottom-pagination */

.statistics-reports-container {
  padding: 20px;
}

/* New styles for filter section */
.filter-section {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Adjust the reports grid */
.reports-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px 0; /* Add padding to separate from filter and bottom sections */
}

.report-card {
  background-color: #f5f7fa;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
}

.report-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
}

.report-icon {
  background-color: #e9ecef;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 15px;
}

.report-icon img {
  width: 30px;
  height: 30px;
}

.report-card h3 {
  margin: 0;
  font-size: 16px;
  color: #333;
}

/* New styles for bottom section */
.bottom-section {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Adjust bottom pagination styles */
.bottom-pagination {
  /* existing styles */
  padding-top: 20px; /* Add padding to separate from the reports grid */
}

/* existing bottom-pagination styles... */
