.contact-information {
  background: #ebecec85;
  padding: 15px;
  border: var(--border-03);
  border-radius: 12px;
}

.contact-information .info-conatct-1 :is(.text, .content-2)  {
  font-size: 15px;
  font-weight: 600;
  color: var(--gray-01-color);
  cursor: pointer;
}
.contact-information .info-conatct-1 .content-2{
   color: var(--dark-01-color);
}
.contact-information .title {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-01-color);
}
.contact-information .bottom-info{
    border-top: var(--border-03);
    border-color: var(--main-01-color);
    padding-top: 14px;
}
.contact-information .bottom-info .title{
    font-size: 16px;
    font-weight: 600;
    color: var(--gray-01-color);
}

.contact-information .bottom-info .list-one .icon-social{
    color: var(--main-01-color);
    font-size: 17px;
}