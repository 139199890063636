.all-page-success-error {
  height: 73vh;
}
.all-page-success-error .title {
  font-size: 25px;
  font-weight: 600;
  color: #4def30;
}

.all-page-success-error .text {
  font-size: 20px;
  font-weight: 500;
  color: var(--dark-01-color);
  max-width: 380px;
  line-height: 1.8;
  padding-top: 12px !important;
}

.all-page-success-error .text .number-order {
  color: var(--red-dark-01-color);
  font-weight: 600;
}

.all-page-success-error .failed-error {
  color: #e30202;
}
