/* START STEP ONE */
.title-header-step {
  font-size: 22px;
  font-weight: 600;
  color: var(--dark-01-color);
}

.step-one-info .error-title {
  font-size: 18px;
  font-weight: 600;
  color: var(--red-dark-01-color);
  padding-top: 20px;
}
.step-one-info .all-employees-info {
  flex-wrap: nowrap !important;
  overflow: auto hidden;
  padding: 2rem 0;
}
@media screen and (max-width: 411px) {
  .step-one-info .error-title {
    font-size: 16px;
  }
}

/* END STEP ONE */
/* START STEP TWO */
.step-two-info .all-info-step .confirm-one .content-label {
  background: #f2f9f3;
  padding: 15px;
  border-radius: 16px;
  margin-top: 25px;
  cursor: pointer;
}
.step-two-info .all-info-step .confirm-one .title-text,
.step-two-info .all-info-step .confirm-one .content-label .title-text {
  font-size: 20px;
  font-weight: 500;
  color: var(--dark-01-color);
}

.step-two-info .all-info-step .confirm-one .content-label .header-top .icon-a {
  color: var(--red-dark-01-color);
  font-size: 25px;
}
.step-two-info .all-info-step .confirm-one .content-label .text {
  font-size: 17px;
  font-weight: 500;
  color: var(--gray-01-color);
  line-height: 1.8;
  padding-top: 10px !important;
}

@media screen and (max-width: 450px) {
  .step-two-info .all-info-step .confirm-one .content-label .text {
    font-size: 15px;
  }
}
/* END STEP TWO */

/* START STEP THREE */
.all-head-details .head-details-discount :is(.num-price, .title) {
  color: var(--red-dark-01-color);
}
.info-employee .rate-num {
  width: fit-content;
  padding: 6px 12px;
  border-radius: 50px;
}
.right-info-content-step .header-one-step .title {
  font-size: 27px;
  padding-bottom: 12px !important;
}
.right-info-content-step .right-main-info .title {
  font-size: 22px;
}
.button-one {
  border: var(--border-06);
  padding: 15px;
  border-radius: 9px;
  font-weight: 600;
  color: var(--dark-01-color);
  cursor: pointer;
}

.button-one.active {
  border-color: var(--main-01-color);
  color: var(--main-01-color);
}
.button-one .icon-check {
  font-size: 17px;
  font-weight: 600;
  color: var(--white-01-color);
  background: var(--main-01-color);
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-info .btn-code {
  background: transparent;
  font-size: 17px;
  font-weight: 500;
  color: var(--main-01-color);
  border: none;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}
html[dir="ltr"] .form-info .btn-code {
  left: initial;
  right: 15px;
}
.bottom-text-content .text {
  position: relative;
}
.bottom-text-content .text::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 2px;
  background: var(--gray-01-color);
  right: -20px;
  top: 66%;
  transform: translateY(-50%);
}

@media screen and (max-width: 450px) {
  .bottom-text-content .text::after {
    display: none;
  }
}

.main-serv-date .owl-carousel {
  margin-right: 2px;
}
html[dir="ltr"] .main-serv-date .owl-carousel {
  margin-right: 0;
  margin-left: 0px;
}
.main-serv-date .owl-carousel {
  padding-bottom: 25px;
}
.main-serv-date .owl-nav {
  top: -35%;
  left: -10px;
}
html[dir="ltr"] .main-serv-date .owl-nav {
  right: -10px;
  left: initial;
}
.main-serv-date .name-date-month {
  font-size: 20px;
  font-weight: 600;
  padding: 15px 0 25px 0 !important;
}

.main-serv-date .btn-filter-one {
  display: flex;
  flex-direction: column;
  color: var(--main-01-color);
  font-weight: 700;
  gap: 8px;
  width: 84px;
  padding: 13px 0;
}
.main-serv-date .btn-filter-one:hover {
  color: var(--white-01-color);
}
.main-serv-date .btn-filter-one span {
  display: block;
}
/* END STEP THREE */

/* STEPS PROGRESS */

.main-info-steps-content {
  padding-top: 80px;
}

#checkout-progress {
  width: 90%;
  margin: 0px auto;
  font-size: 2.5em;
  font-weight: 900;
  position: relative;
}
@media (max-width: 767px) {
  #checkout-progress {
    font-size: 1.5em;
  }
}
#checkout-progress:before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  height: 7px;
  width: 100%;
  background-color: #ccc;
  -webkit-transform: translateY(-50%) perspective(1000px);
  transform: translateY(-50%) perspective(1000px);
}
#checkout-progress:after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  height: 7px;
  width: 100%;
  background-color: #2c3e50;
  -webkit-transform: scaleX(0) translateY(-50%) perspective(1000px);
  transform: scaleX(0) translateY(-50%) perspective(1000px);
  -webkit-transform-origin: right center;
  transform-origin: right center;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition:
    transform 0.5s ease,
    -webkit-transform 0.5s ease;
}
html[dir="ltr"] #checkout-progress:after {
  -webkit-transform-origin: left center;
}
.form-check-label {
  margin-left: 20px;
}
#checkout-progress.step-2:after {
  -webkit-transform: scaleX(0.333) translateY(-50%) perspective(1000px);
  transform: scaleX(0.333) translateY(-50%) perspective(1000px);
}
#checkout-progress.step-3:after {
  -webkit-transform: scaleX(0.666) translateY(-50%) perspective(1000px);
  transform: scaleX(0.666) translateY(-50%) perspective(1000px);
}
#checkout-progress.step-4:after {
  -webkit-transform: scaleX(1) translateY(-50%) perspective(1000px);
  transform: scaleX(1) translateY(-50%) perspective(1000px);
}
#checkout-progress .progress-bar {
  width: 100%;
  display: flex;
  height: 100px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: initial;
}
#checkout-progress .progress-bar .step {
  z-index: 2;
  position: relative;
}
#checkout-progress .progress-bar .step .step-label {
  position: absolute;
  top: calc(100% + 25px);
  left: 50%;
  -webkit-transform: translateX(-50%) perspective(1000px);
  transform: translateX(-50%) perspective(1000px);
  white-space: nowrap;
  font-size: 0.4em;
  font-weight: 600;
  color: #ccc;
  transition: 0.3s ease;
}

@media (max-width: 767px) {
  #checkout-progress .progress-bar .step .step-label {
    top: calc(100% + 15px);
  }
}
#checkout-progress .progress-bar .step .num-step::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: var(--main-01-color);
  border-radius: 50%;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
#checkout-progress .progress-bar .step span {
  color: #ccc;
  transition: 0.3s ease;
  display: block;
  -webkit-transform: translate3d(0, 0, 0) scale(1) perspective(1000px);
  transform: translate3d(0, 0, 0) scale(1) perspective(1000px);
  opacity: 0;
}

#checkout-progress .progress-bar .step.active span,
#checkout-progress .progress-bar .step.active .step-label {
  color: #2c3e50;
}

#checkout-progress .progress-bar .step:after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) perspective(1000px);
  transform: translate(-50%, -50%) perspective(1000px);
  width: 60px;
  height: 60px;
  background-color: #fff;
  border-radius: 50%;
  border: 5px solid #ccc;
  transition: 0.3s ease;
}

#checkout-progress .progress-bar .step.active:after {
  border: 5px solid #2c3e50 !important;
}
#checkout-progress .progress-bar .step:after {
  background-color: #fff !important;
  border: 5px solid #2c3e50 !important;
}

#checkout-progress .progress-bar .step.active1:after {
  background-color: #2c3e50 !important;
  border: 5px solid #2c3e50 !important;
}

#checkout-progress .progress-bar .step .opaque {
  width: 60px;
  height: 60px;
  background: var(--main-01-color);
  position: absolute;
  top: 0;
  right: 50%;
  transform: translateX(50%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  color: var(--white-01-color);
  opacity: 0;
  visibility: hidden;
  transform: all 0.5s;
}
#checkout-progress .progress-bar .step.active1 .opaque {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 767px) {
  #checkout-progress {
    width: 90%;
  }
  #checkout-progress .progress-bar .step:after,
  #checkout-progress .progress-bar .step .opaque {
    width: 40px;
    height: 40px;
  }
  #checkout-progress .progress-bar .step .num-step::after {
    width: 15px;
    height: 15px;
  }
}
.button-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin: 50px auto 0px;
  gap: 15px;
}
.button-container .btn {
  display: inline-block;
  background-color: var(--main-01-color);
  color: var(--white-01-color);
  padding: 10px 35px;
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: 900;
  border: 3px solid var(--main-01-color);
  transition: 0.3s ease;
  cursor: pointer;
  text-align: center;
}
