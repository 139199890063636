/* START SPINNER LOADER */
.loader {
    width: 100%;
    background: var(--white-01-color);
    bottom: 0;
    z-index: 1000;
    transition: all 0.5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;
  }
  
  /* END SPINNER LOADER */
  