.cards-advantages {
  background: #f9f8fe;
  padding: 80px 0;
}
.cards-advantages .all-cards-advantages {
  padding: 25px 0 0 0;
}
.cards-advantages .card-advantage-one {
  border: var(--border-06);
  padding: 25px;
  background: var(--white-01-color);
  border-radius: 12px;
  text-align: center;
  transition: all 0.5s;
  cursor: pointer;
  height: 100%;
}
.cards-advantages .card-advantage-one:hover {
  border-color: var(--main-01-color);
}

.cards-advantages .card-advantage-one .title {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-01-color);
  padding: 15px 0 !important;
}

.cards-advantages .card-advantage-one .text {
  font-size: 17px;
  font-weight: 600;
  color: var(--gray-01-color);
  line-height: 1.9;
}

@media screen and (max-width: 450px) {
  .cards-advantages .card-advantage-one {
    padding: 25px 10px;
  }
  .cards-advantages .card-advantage-one .title {
    font-size: 17px;
  }

  .cards-advantages .card-advantage-one .text {
    font-size: 15px;
  }
}
