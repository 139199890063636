.page-title {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 50px 0;
  margin-bottom: 70px;
}

.page-title .title {
  font-size: 28px;
  font-weight: 600;
  color: var(--dark-01-color);
  text-align: center;
}
