.privacy-content .title {
    font-size: 30px;
    font-weight: 600;
    color: var(--dark-01-color);
  }
  
  .privacy-content .text >*{
    font-size: 17px;
    font-weight: 500;
    color: var(--dark-03-color);
    line-height: 1.9;
    padding-top: 15px !important;
  }
  
  @media screen and (max-width: 450px) {
    .privacy-content .title {
      font-size: 25px;
    }
  
    .privacy-content .text {
      font-size: 15px;
    }
  }
  