.section-title {
  padding-bottom: 25px;
}
.section-title .title {
  font-size: 32px;
  font-weight: 600;
  color: var(--dark-01-color);
}

.section-title .text {
  font-size: 20px;
  font-weight: 600;
  color: var(--gray-01-color);
  padding-top: 12px !important;
}

@media screen and (max-width: 450px) {
  .section-title .title {
    font-size: 24px;
    font-weight: 700;
  }
  .section-title .text {
    font-size: 15px;
  }
}

@media screen and (max-width: 340px) {
  .section-title .title {
    font-size: 22px;
  }
  .section-title .text {
    font-size: 13px;
    font-weight: 700;
  }
}
