.modal-filter .header-input-filter {
  position: relative;
  border: var(--border-06);
  border-radius: 9px;
}
.modal-filter .header-input-filter input {
  border: none;
  padding-right: 44px !important;
  background: transparent;
}

.modal-filter .header-input-filter input:focus {
  box-shadow: none;
}
.modal-filter .header-input-filter .icon-search {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
}

.main-content-modal .content-one-info {
  padding: 14px 0;
}
.main-content-modal .content-one-info .form-check {
  margin-top: 15px;
}
.main-content-modal .content-one-info .form-check,
.main-content-modal .content-one-info .form-check .form-check-label {
  cursor: pointer;
}
.main-content-modal .content-one-info .title {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-01-color);
}
.main-content-modal .content-one-info .form-check-label {
  font-size: 17px;
  margin-right: 15px;
  font-weight: 500;
}

.form-check-input:checked {
  background-color: var(--main-01-color);
  border-color: var(--main-01-color);
}

.input-range {
  accent-color: var(--main-01-color);
  width: 100%;
  padding: 0 !important;
}
.info-range .text {
  font-size: 17px;
  font-weight: 500;
  color: var(--gray-01-color);
}
.info-range .num-price {
  font-size: 25px;
  font-weight: 600;
  color: var(--dark-01-color);
}

.input-range {
  -webkit-appearance: none;
}

.input-range:focus {
  outline: none;
}

.input-range::-webkit-slider-runnable-track {
  background: var(--main-01-color);
  width: 100%;
  height: 5px;
  cursor: pointer;
  border-radius: 8px;
}

.input-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 25px;
  width: 25px;
  background: var(--main-01-color);
  border-radius: 50%;
  border: 1px solid var(--main-01-color);
  margin-top: -10px;
  cursor: pointer;
}

.form-check .form-check-input {
  margin-top: 0;
}
.main-content-modal .content-one-info .form-check {
  display: flex;
  align-items: center;
}
