.footer-landing {
  padding: 50px 0 0 0;
}

.footer-landing .final-footer {
  margin-top: 52px;
}
.footer-landing .main-footer-content {
  max-width: 500px;
  margin: auto;
  text-align: center;
}
.footer-landing .final-footer .text-footer {
  font-size: 20px;
}
.footer-landing .main-links-bottom .link-footer-bottom {
  font-size: 17px;
  font-weight: 600;
  color: var(--white-01-color);
}

@media screen and (max-width: 767px) {
  .footer-landing .main-footer-content {
    max-width: 100%;
  }
}

@media screen and (max-width: 399px) {
  .footer-landing .final-footer {
    flex-direction: column !important;
    text-align: center;
  }
  .footer-landing .final-footer .text-footer {
    font-size: 17px;
  }
  .footer-landing .main-links-bottom .link-footer-bottom {
    font-size: 15px;
  }
}
