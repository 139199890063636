.calender .table > :not(caption) > * > * {
  border-right: var(--border-06);
}
.calender .table > thead {
  text-align: center;
}
.calender .table > :is(tbody, thead) {
  border: var(--border-06);
}
.calender .table tbody {
  text-align: right;
  vertical-align: middle;
}
.calender .table :is(.date-time, .name-employee, .title-job) {
  font-size: 17px;
  font-weight: 600;
  color: var(--dark-01-color);
  padding-top: 10px !important;
}
.calender .table .content-info-employee .name-employee {
  font-size: 22px;
}
.calender .table .content-info-employee .title-job {
  padding: 8px 0 0 0 !important;
}

.calender .table .time-one {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-01-color);
}

.calender .bg-color-1 {
  background: #a5dff7;
}

.calender .bg-color-2 {
  background: #ffbe69;
}

.calender .bg-color-3 {
  background: #ff9dbc;
}

.calender .table :is(th, tr, .bg-color-transparent) {
  background: var(--white-01-color);
}

.header-search-calender :is(.input-date-info, .form-search, select, .react-datepicker-wrapper) {
  width: 100%;
}
.header-search-calender .select-input select {
  padding: 12px;
}

.calender .td-bg.active {
  background: var(--main-01-color);
}

.calender .td-bg.active :is(.date-time, .name-employee, .title-job) {
  color: var(--white-01-color);
}

@media screen and (max-width: 767px) {
  .calender .table .content-info-employee .name-employee {
    font-size: 17px;
  }
}

@media screen and (max-width: 650px) {
  .calender .table {
    width: 800px;
  }
}

.calender .week-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.calender .week-navigation .nav-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #05445e;
}

.calender .week-navigation .date-range {
  font-size: 18px;
  font-weight: 600;
  color: #05445e;
}

@media screen and (max-width: 767px) {
  .calender .week-navigation .date-range {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  .calender .week-navigation .date-range {
    font-size: 14px;
  }
}

@media screen and (max-width: 320px) {
  .calender .week-navigation .date-range {
    font-size: 12px;
  }
}
