.header-update-profile {
  padding-bottom: 25px;
}
.header-update-profile :is(.title, .back-link) {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-01-color);
}
.header-update-profile .title {
  font-size: 25px;
}
.header-update-profile :is(.back-link) {
  color: var(--main-01-color);
}

@media screen and (max-width: 335px) {
  .header-update-profile .title {
    font-size: 17px;
  }
}
