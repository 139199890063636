.help-center .all-help-center .help-card-one {
  position: relative;
  cursor: pointer;
}

.help-center .all-help-center .help-card-one img,
.details-help-center img {
  border: var(--border-03);
  border-radius: 8px;
}
.help-center .all-help-center .help-card-one .text-title {
  position: absolute;
  bottom: 20px;
  right: 15px;
}
.help-center .all-help-center .help-card-one .text-title .title,
.details-help-center .title {
  font-size: 20px;
  font-weight: 600;
  color: var(--white-01-color);
}

.details-help-center .title {
  color: var(--dark-01-color);
}

.details-help-center .text {
  font-size: 17px;
  font-weight: 600;
  color: var(--gray-01-color);
}

.details-help-center .help-card-one img {
  width: 300px !important;
}

@media screen and (max-width: 575px) {
  .details-help-center .help-card-one img {
    width: 100% !important;
  }
  .details-help-center .help-card-one .text-title {
    gap: 15px !important;
  }
}
