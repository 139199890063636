.setting-card {
  padding: 20px;
  cursor: pointer;
}

.setting-card .right-card-setting .title {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-01-color);
}

.setting-card .right-card-setting .text {
  font-size: 15px;
  font-weight: 600;
  color: var(--gray-01-color);
  padding-top: 12px !important;
}

.setting-card .arrow-left {
  font-size: 22px;
  color: var(--dark-01-color);
  cursor: pointer;
}

.all-cards-settings .card-setting-content:not(:last-child) {
  border-bottom: var(--border-03);
}

.select-box-option-1 {
  font-size: 17px;
  font-weight: 500;
  padding: 12px;
}

@media screen and (max-width: 550px) {
  .all-cards-settings .main-cards-settings {
    padding: 0px !important;
  }
}

/* START CARD RECEIVE */
.card-receive {
  padding: 27px 20px;
  border-radius: 12px;
  background: var(--white-01-color);
}

.card-receive-1:not(:last-child) {
  border-bottom: var(--border-03);
}
.card-receive .title,
.main-cards-one .title-head-card {
  font-size: 18px;
  font-weight: 600;
  color: var(--dark-01-color);
  line-height: 1.8;
}
.main-cards-one .title-head-card {
  padding: 10px 20px 20px 20px !important;
}
.main-cards-one {
  padding: 15px;
  border: var(--border-03);
  border-radius: 16px;
  margin: 25px 0;
}
#all-details-settings .main-info-one:nth-child(1) {
  width: 22% !important;
}
@media screen and (max-width: 800px) {
  #all-details-settings .main-info-one:nth-child(1) {
    width: 35% !important;
  }
}
@media screen and (max-width: 575px) {
  #all-details-settings .main-info-one:nth-child(1) {
    width: 45% !important;
  }
}
@media screen and (max-width: 450px) {
  .card-receive {
    padding: 22px 12px;
  }
  .card-receive .title {
    font-size: 15px;
  }
  .main-cards-one {
    padding: 12px 10px;
  }
}

.icons-social-com .icon-social {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  color: var(--white-01-color);
  background: var(--main-01-color);
  border-radius: 8px;
  cursor: pointer;
}

.content-user-info {
  position: relative;
}
.content-user-info .icon-edit-pen {
  position: absolute;
  top: 25px;
  left: 25px;
}
/* END CARD RECEIVE */

/* START PAGE PAY */

.card-pay-1 {
  border: var(--border-03);
  width: 100%;
  padding: 15px;
  height: 100%;
  min-height: 120px;
  border-radius: 12px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.card-pay-1.active {
  border-color: var(--main-01-color);
  box-shadow: 0 0 10px #093a4e52;
}

.card-pay-1 .title {
  font-size: 17px;
  font-weight: 600;
  color: var(--dark-01-color);
}
.card-pay-1 .img-1 img {
  width: 100%;
  height: 50px;
}
.card-pay-1 .img-1 {
  border: var(--border-03);
  width: 120px;
  height: 80px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.card-pay-1 .card-content {
  margin-top: 10px;
}

@media screen and (max-width:353px) {
  .card-pay-1 .img-1{
    width: 100%;
    
  }
  .card-pay-1 .img-1 img {
    width: 100px;
    height: 50px;
  }
}

/* END PAGE PAY */
