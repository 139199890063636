.provider-subscription .card-provider-subscription {
  border: var(--border-03);
  background: var(--white-01-color);
  padding: 20px;
  border-radius: 16px;
}
.provider-subscription .card-provider-subscription :is(.title, .text) {
  font-size: 17px;
  font-weight: 700;
  color: var(--dark-01-color);
}

.provider-subscription .card-provider-subscription .text {
  color: var(--gray-01-color);
  font-weight: 600;
  line-height: 1.8;
}
@media screen and (max-width: 450px) {
  .provider-subscription .card-provider-subscription :is(.title, .text) {
    font-size: 15px;
  }
  .provider-subscription .card-provider-subscription .data-info {
    flex-wrap: wrap;
  }
}
