.all-products .main-products {
  overflow: hidden;
  padding: 0 10px;
}
.all-products .main-products .mySwiper {
  overflow: unset;
  padding: 0 3px;
}

.all-products .main-products .mySwiper .swiper-button-prev,
.all-products .main-products .mySwiper .swiper-button-next {
  transition: all 0.5s;
}
.all-products .main-products .mySwiper .swiper-button-prev {
  margin-right: -100px;
}
.all-products .main-products .mySwiper .swiper-button-next {
  margin-left: -100px;
}
.all-products .main-products:hover .mySwiper .swiper-button-prev {
  margin-right: -40px;
}
.all-products .main-products:hover .mySwiper .swiper-button-next {
  margin-left: 0px;
}

.services-providers .main-products {
  overflow: unset;
  padding: 0;
}

@media screen and (max-width: 575px) {
  .all-products .main-products {
    padding: 0px;
  }
  .all-products .main-products .mySwiper {
    padding: 0px;
  }

  .all-products .main-products:hover .mySwiper .swiper-button-prev {
    margin-right: -20px;
  }
  .all-products .main-products:hover .mySwiper .swiper-button-next {
    margin-left: 20px;
  }
}
