.select-box-option .form-select-lg {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.header-home-index {
  background: var(--main-01-color);
  padding: 25px;
  border-radius: 16px;
  margin: 15px 0;
}
.header-home-index .title-head {
  font-size: 20px;
  font-weight: 600;
  color: var(--white-02-color);
}
.header-home-index .main-info-head .main-btn {
  border: var(--border-07);
  color: var(--white-02-color);
}

@media screen and (max-width: 450px) {
  .header-home :is(.left-info-page-content, .main-btn, .select-box-option) {
    width: 100%;
  }
}

@media screen and (max-width: 375px) {
  .header-home-index .title-head {
    font-size: 15px;
  }

  .header-home-index .main-info-head .main-btn {
    width: 100%;
  }
}
@media screen and (max-width: 356px) {
  .header-home .left-info-page-content .info-content-head {
    flex-wrap: wrap;
  }
}

.chart-container {
  background: #cfe5e82e;
  padding: 30px 10px 0 10px;
  border-radius: 16px;
  position: relative;
  border: 2px solid var(--white-03-color);
  width: 100%;
}

.chart-container .all-buttons {
  display: flex;
  align-items: center;
  background: var(--white-01-color);
  width: fit-content;
  padding: 8px 10px;
  gap: 12px;
  border-radius: 16px;
  position: absolute;
  top: 30px;
  right: 15px;
  z-index: 11;
}
.chart-container .all-buttons .button-1 {
  background: transparent;
  border-color: transparent;
  font-weight: 600;

  font-size: 15px;
}
.chart-container .all-buttons .active {
  background: var(--main-01-color);
  border: var(--border-01);
  border-color: var(--main-01-color);
  border-radius: 50px;
  color: var(--white-01-color);
  padding: 6px 20px;
}

@media screen and (max-width: 1345px) {
  .all-charts .col-xl-6 {
    flex: 100%;
    width: 100%;
  }
  .chart-container #myChart > div {
    width: 100% !important;
  }
}

/* =======================
START PROGRESS BAR 
======================== */
.main-info-head .progress-container-1 {
  width: 100%;
  height: 12px;
  background-color: var(--white-02-color);
  border-radius: 5px;
  position: relative;
}

.main-info-head .progress-bar-1 {
  height: 100%;
  background-color: var(--red-dark-01-color);
  border-radius: 5px;
}

.main-info-head .progress-head {
  width: 400px;
}

.main-info-head .progress-label-1 {
  position: absolute;
  top: 50%;
  left: -66px;
  transform: translateX(-50%);
  background-color: var(--red-dark-01-color);
  color: var(--white-01-color);
  padding: 5px 10px;
  border-radius: 5px;
  pointer-events: none;
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .main-info-head .progress-head {
    width: 100%;
  }

  .main-info-head .progress-label-1 {
    position: absolute;
    top: -25px;
    left: 0px;
  }
  
}

/* =======================
END PROGRESS BAR 
======================== */
