* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none !important;
  font-family: "Cairo", sans-serif !important;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

:root {
  --main-01-color: #093a4e;
  --dark-01-color: #2d2d2d;
  --dark-02-color: #363243;
  --dark-03-color: #1c1c1c;
  --dark-04-color: #333333;
  --white-01-color: #ffffff;
  --white-02-color: #ebecec;
  --white-03-color: #f9f9f9;
  --white-04-color: rgba(235, 236, 236, 0.6);
  --white-05-color: rgba(235, 236, 236, 0.6);
  --red-dark-01-color: #9c4651;
  --gray-01-color: #636363;
  --gray-02-color: rgba(99, 99, 99, 0.4);
  --gray-03-color: #949494;
  --gray-04-color: #fafafa;
  --box-shadow: 0px 24px 40px #3d3d751f;
  --box-shadow-1: 0 4px 16px rgba(0, 0, 0, 0.08);
  --overlay-bg: rgba(0 0 0 / 25%);
  --overlay-01-bg: rgba(0 0 0 / 72%);
  --border-01: 1px solid #ffffff;
  --border-02: 1px solid #d2dadf;
  --border-03: 1px solid #ebecec;
  --border-04: 1px solid #d4d8e5;
  --border-05: 1px solid rgba(99, 99, 99, 0.4);
  --border-06: 1px solid rgba(99, 99, 99, 0.2);
  --border-07: 1px solid rgba(235, 236, 236, 0.2);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0 !important;
  margin: 0 !important;
}
body {
  background: var(--white-01-color) !important;
  min-height: 100vh;
  overflow: hidden;
  padding: 0 !important;
  position: relative;
}

.filepond--credits {
  display: none;
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.width-fit {
  width: fit-content;
}

.cursor-pointer-1 {
  cursor: pointer;
}
.padding-x {
  padding: 0 16px;
}

.padding {
  padding: 100px 0;
}
.padding-bottom-35 {
  padding-bottom: 35px;
}
.padding-bottom-50 {
  padding-bottom: 50px;
}
.padding-bottom-70 {
  padding-bottom: 70px;
}
.padding-bottom-100 {
  padding-bottom: 100px;
}

.img-bg {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}
.main-btn {
  padding: 12px 22px;
  background: var(--main-01-color);
  color: var(--white-01-color);
  font-size: 17px;
  font-weight: 600;
  border-radius: 8px;
  border: var(--border-01);
  border-width: 1.5px;
  border-color: var(--main-01-color);
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  text-align: center;
  gap: 10px;
}
.main-outline-btn {
  background: transparent;
  color: var(--main-01-color);
  transition: all 0.5s;
}

.main-outline-btn:hover {
  background: var(--main-01-color);
  color: var(--white-01-color);
}

@media screen and (max-width: 480px) {
  .Toastify__toast-container--rtl {
    padding: 10px !important;
  }
  .Toastify__toast-theme--colored {
    margin-bottom: 10px !important;
  }
}

/* START SWIPER */
.swiper-pagination-bullet {
  width: 15px !important;
  height: 15px !important;
  background: #c6c1c1 !important;
  opacity: 1 !important;
  transition: all 0.5s;
}

.swiper-pagination-bullets {
  bottom: 30px !important;
}

.swiper-bullets-color .swiper-pagination-bullet {
  background: var(--gray-02-color) !important;
}

.swiper-pagination-bullet-active,
.swiper-bullets-color .swiper-pagination-bullet-active {
  background: var(--white-01-color) !important;
}

.card-Section .swiper-pagination-bullets {
  bottom: 0px !important;
}

.swiper-rtl .swiper-button-prev,
.swiper-rtl .swiper-button-next {
  color: var(--gray-01-color);
  width: 40px;
  height: 40px;
  font-size: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: center;
  transition: all 0.5s;
  cursor: pointer;
  position: absolute;
  bottom: 12px;
  z-index: 1;
  font-size: 19px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.5s;
  background: var(--white-01-color);
  border: var(--border-03);
}
.swiper-rtl .swiper-button-prev:hover,
.swiper-rtl .swiper-button-next:hover {
  color: var(--white-01-color);
  background: var(--main-01-color);
  border-color: var(--main-01-color);
}
.swiper-rtl .swiper-button-prev {
  margin-right: -40px;
}
.swiper-rtl .swiper-button-next {
  margin-left: 0px;
}
.swiper-rtl .swiper-button-prev:after,
.swiper-rtl .swiper-button-next::after {
  font-weight: 600;
  font-size: 20px;
}
/* END SWIPER */

/* START FORM INPUT */

.form-style {
  background: var(--white-01-color);
  padding: 30px;
  border-radius: 16px;
  box-shadow: var(--box-shadow);
}

.input-pass .error {
  position: absolute;
  bottom: -28px;
}
.input-pass .icon-eye-button {
  position: absolute;
  top: 71%;
  left: 3%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
}

.input-pass .form-control.is-invalid,
.was-validated .input-pass .form-control:invalid {
  background-image: none;
}

.error {
  font-size: 16px;
  font-weight: 500;
  color: red;
}
input::placeholder,
textarea::placeholder {
  font-size: 15px;
  color: var(--gray-01-color) !important;
  font-weight: 600;
}

input,
textarea,
.form-control {
  padding: 12px !important;
  text-align: right !important;
  border-color: var(--gray-02-color);
}

.form-select-ltr {
  padding: 0;
}

label {
  font-size: 15px;
  color: var(--gray-01-color);
  font-weight: 600;
}

textarea {
  resize: none !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

/* END FORM INPUT */
.owl-carousel,
.bx-wrapper {
  direction: ltr !important;
}
.owl-carousel,
.owl-item {
  direction: rtl;
}

/* ICON STYLE */
.icon-style-1 {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(9, 58, 78, 0.1);
  border-radius: 50%;
  cursor: pointer;
  border: var(--border-07);
  transition: all 0.5s;
}
.icon-style-1:hover {
  border-color: var(--main-01-color);
}

.icons-rate-1 .icon-star {
  font-size: 17px;
  color: #be8c4d;
}
