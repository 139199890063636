.main-container {
  width: 100%;
  padding: 1rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.customer-info {
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 1rem;
}

.customer-info .section-title {
  text-align: right;
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 0.5rem;
}

.customer-info-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 8px;
  padding: 1.5rem;
}

.info-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.info-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
}

.info-label {
  color: #666;
  font-weight: 500;
  font-size: 0.95rem;
}

.info-value {
  color: #333;
  font-size: 0.95rem;
  text-align: right;
}

.invoice-outer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  background-color: #f0f0f0;
  padding: 2rem;
  border-radius: 8px;
}

.invoice-container {
  max-width: 80%;
  background-color: white;
  margin-top: 2rem;
  margin: 0 1rem;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.invoice-content {
  border-radius: 10px;
  box-shadow: none;
  padding: 1rem;
  width: 100%;
}

.invoice-header {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 1rem;

  .invoice-details {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    .detail-row {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 1rem;
    }
  }
}

.invoice-title {
  font-size: 24px;
  font-weight: bold;
  color: #000;
  margin-bottom: 1rem;
}

.detail-row {
  margin-bottom: 8px;
  display: flex;
  gap: 12px;
}

.detail-row .label {
  color: #666;
  font-size: 14px;
}

.detail-row .value {
  font-weight: 500;
}

.vendor-info {
  margin-top: 2rem;
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 8px;
  color: #000;
  font-size: 14px;
  margin-bottom: 2rem;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .vendor-info-col {
    width: 50%;
  }

  .detail-row {
    margin-bottom: 8px;
    display: flex;
    gap: 12px;

    .label {
      color: #0a0808;
      font-size: 14px;
    }

    .value {
      font-weight: 500;
      color: #6e5d5d;
    }
  }
}

.invoice-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 2rem;
  font-size: 14px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #ddd;
}

.invoice-table th {
  background-color: #35494e;
  color: white;
  padding: 12px 8px;
  text-align: center;
  padding: 1rem;
}

.invoice-table th:last-child {
  border-right: none;
}

.invoice-table td {
  padding: 1rem;
  text-align: center;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
}

.invoice-table td:last-child {
  border-right: none;
}

.invoice-table tr {
  background-color: #004351;
}

.invoice-table tr:nth-child(odd) {
  background-color: #b88a5f;
}

.totals-section {
  max-width: 400px;
  margin-top: 2rem;
  border-radius: 8px;
  border: 1px solid #ddd;
  margin-right: auto;
}

.total-row {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  background-color: #f8f9fa;
}

.total-row:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.total-row.final-total {
  background-color: #004351;
  color: white;
  font-weight: bold;
}

.footer-link {
  color: #004351;
  text-decoration: none;
  font-weight: 500;
  margin-top: 2rem;
  display: inline-block;
}

.footer-link:hover {
  text-decoration: underline;
}

/* Print styles */
@media print {
  .invoice-actions {
    display: none;
  }

  .invoice-container {
    padding: 0 !important;
  }

  .invoice-content {
    box-shadow: none;
  }
}

.info-container {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  gap: 2rem;
}

.info-section {
  flex: 1;
  background: #fff;
  border-radius: 8px;
  padding: 1rem;
}

.section-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1.5rem;
  text-align: right;
}

.info-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #f0f0f0;
}

.info-row:last-child {
  border-bottom: none;
}

.info-label {
  color: #666;
  font-size: 0.9rem;
  font-weight: 500;
}

.info-value {
  color: #333;
  font-size: 0.9rem;
}
