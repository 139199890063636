.header-info-top {
  padding: 30px 0;
}

.header-page-1 .all-header-page-title {
  padding: 30px 0;
}
.all-header-page-title .title-text {
  font-size: 17px;
  font-weight: 500;
  color: var(--gray-01-color);
}

.all-header-page-title .dot-page {
  width: 12px;
  height: 12px;
  display: block;
  background: #d9d9d9;
  border-radius: 50%;
}

@media screen and (max-width: 397px) {
  .header-info-top .link-back {
    padding-right: 12px;
  }
 
}
@media screen and (max-width: 370px) {
  .all-header-page-title .title-text {
    font-size: 14px;
  }

  .all-header-page-title .dot-page {
    width: 10px;
    height: 10px;
  }
}
@media screen and (max-width: 345px) {
  .all-header-page-title .title-text {
    font-size: 12px;
    font-weight: 700;
  }
}
