.banner-home {
  background: var(--main-01-color);
  margin: 50px 0;
}
.banner-home .content-banner-info {
  padding: 50px 60px;
}
.banner-home .content-banner-info :is(.head-title, .title) {
  color: var(--white-01-color);
  font-weight: 600;
}
.banner-home .content-banner-info .head-title {
  font-size: 18px;
  padding-bottom: 15px !important;
}
.banner-home .content-banner-info .title {
  font-size: 28px;
  padding-bottom: 15px !important;
}
.banner-home .content-banner-info .text {
  font-size: 16px;
  font-weight: 500;
  color: var(--white-02-color);
  line-height: 1.8;
  padding: 12px 0 !important;
}

.banner-home .content-banner-info .main-btn {
  border-color: var(--white-01-color);
  color: var(--white-01-color);
  margin-top: 20px;
}

/* SCREEN 2400px */
@media screen and (max-width: 2400px) {
  .banner-home .content-banner-info {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
  .banner-home .image-banner {
    height: 100vh;
  }
}

/* SCREEN 1197PX */

@media screen and (max-width: 1197px) {
  .banner-home {
    overflow: hidden;
  }
  .banner-home .image-banner {
    height: 100%;
  }

  .banner-home .image-banner img {
    height: 600px !important;
  }
}

/* SCREEN 1079PX */
@media screen and (max-width: 1079px) {
  .banner-home .image-banner img {
    height: 66vw !important;
  }
  .banner-home .content-banner-info .title {
    font-size: 24px;
  }
  .banner-home .content-banner-info .text {
    font-size: 14px;
  }
}

/* SCREEN 575PX */
@media screen and (max-width: 991px) {
  .banner-home .image-banner img,
  .banner-home .image-banner {
    height: 100% !important;
  }
  .banner-home .content-banner-info .title {
    font-size: 28px;
  }
  .banner-home .content-banner-info .text {
    font-size: 17px;
  }
}

/* SCREEN 575PX */
@media screen and (max-width: 575px) {
  .banner-home .content-banner-info {
    padding: 50px 15px;
  }
}

/* SCREEN 575PX */
@media screen and (max-width: 450px) {
  .banner-home .content-banner-info .title {
    font-size: 20px;
    line-height: 1.8;
  }
  .banner-home .content-banner-info .text {
    font-size: 14px;
  }
}
