/* START DETAILS OFFER */
.content-info-deails-offer .all-info-content .main-info-one {
  width: 100% !important;
}

.content-info-deails-offer .all-info-content .main-info-one:nth-child(1) {
  width: 30% !important;
}
@media screen and (max-width: 767px) {
  .content-info-deails-offer .all-info-content .main-info-one:nth-child(1) {
    width: 50% !important;
  }
}

@media screen and (max-width: 575px) {
  .content-info-deails-offer .all-info-content .main-info-one:nth-child(1) {
    width: 70% !important;
  }
}

/* END DETAILS OFFER */

/* START EDIT ADD OFFER */
.form-edit-add-offer .info-details-left {
  background: var(--white-02-color);
  border: var(--border-03);
  padding: 15px;
  border-radius: 12px;
}
.form-edit-add-offer .info-details-left .detsila-pay-one:not(:last-child) {
  margin-bottom: 20px;
}
.form-edit-add-offer .info-details-left .detsila-pay-one:last-child {
  border-top: var(--border-06);
  padding-top: 10px;
}
.form-edit-add-offer .info-details-left .detsila-pay-one .title {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-01-color);
}
.form-edit-add-offer
  .info-details-left
  .detsila-pay-one
  :is(.time-add, .price-offer) {
  font-size: 17px;
  font-weight: 600;
  color: var(--gray-01-color);
}
.form-edit-add-offer .info-details-left .detsila-pay-one :is(.time-add) {
  padding-top: 12px !important;
}

.all-basic-info .header-info-profile .main-info-one .text {
  line-height: 1.8;
}
/* END EDIT ADD OFFER */
