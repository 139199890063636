.register-form .link-form-register {
  padding: 0 !important;
}

.form-check .form-check-label {
  font-size: 17px;
  font-weight: 600;
  color: var(--dark-03-color);
  cursor: pointer;
}
.form-check .link-ckeck {
  color: var(--main-01-color);
}


@media screen and (max-width:350px) {
    .form-check .form-check-label {
        font-size: 13px;

      }   
}