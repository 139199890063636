.sidebar {
  width: 280px;
  height: 100vh;
  background-color: var(--main-01-color);
  color: white;
  transition: all 0.3s ease;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  right: 0;
}

.all-sidebar {
  position: fixed;
  top: 0;
  right: -120%;
  width: 100%;
  height: 100vh;
  z-index: 3;
}
.all-sidebar .overlay-bg-1 {
  position: fixed;
  top: 0;
  left: 0;
  right: -120%;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.336);
  z-index: -1;
  transition: all 0.4s ease-in-out;
}

.sidebar .icon-close {
  position: absolute;
  left: 0;
  right: initial;
  top: 0;
  border-radius: 0 0 8px 0;
  color: var(--main-01-color) !important;
  font-size: 20px;
  background: var(--white-01-color);
  width: 40px;
  height: 40px;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media screen and (min-width: 992px) {
  .all-sidebar.open .sidebar {
    width: 100px;
  }
  .all-sidebar.open .sidebar .logo-sidebar {
    width: 60px;
  }

  .all-sidebar.open .sidebar .text-link {
    display: none !important;
  }
  .all-sidebar.open .sidebar .icon-help {
    display: flex !important;
    color: var(--white-04-color) !important;
  }
  .all-sidebar.open .sidebar .icon-link-nav {
    margin: 0 auto 0 26px;
  }

  .contnet-page.activeOpen {
    width: calc(100% - 100px);
    margin-right: 100px;
  }
}

/* Media query for responsiveness */
@media (max-width: 991px) {
  .sidebar .icon-close {
    display: flex;
  }

  .contnet-page {
    width: 100% !important;
    margin-right: 0 !important;
  }

  .sidebar {
    z-index: 1000; /* Increase z-index to ensure it's above other content */
    width: 280px; /* Reduce width for better mobile view */
    right: -280px; /* Hide off-screen initially */
    transition: right 0.3s ease-in-out;
  }

  .sidebar.open {
    right: 0;
  }

  .all-sidebar {
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Add overlay background */
    opacity: 0;
    visibility: hidden;
    transition:
      opacity 0.3s ease-in-out,
      visibility 0.3s ease-in-out;
  }

  .all-sidebar.open {
    opacity: 1;
    visibility: visible;
  }

  .all-sidebar .overlay-bg-1 {
    display: none; /* Remove extra overlay */
  }

  .all-sidebar.open .navbar-nav .nav-item .icon-link-nav {
    margin: initial !important;
  }

  /* Adjust logo size for mobile */
  .logo-sidebar {
    width: 80px;
    padding: 20px 0 0 0;
  }

  /* Adjust navigation padding */
  .sidebar .navbar-nav {
    padding: 20px 0;
  }

  /* Adjust navigation item styles */
  .sidebar .navbar-nav .nav-link {
    padding: 10px 15px;
    font-size: 16px;
  }
}

@media (max-width: 450px) {
  .sidebar {
    width: 250px; /* Further reduce width for very small screens */
    right: -250px;
  }
}

.logo-sidebar {
  width: 110px;
  margin: auto;
  padding: 30px 0 0 0;
}
.logo-sidebar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sidebar .navbar-nav {
  padding: 30px 0;
}

.sidebar .navbar-nav .nav-link {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 12px 15px;
  border-right: 7px solid transparent;
  transform: all 0.5s;
  color: var(--white-04-color);
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
.sidebar .navbar-nav .nav-link.active,
.sidebar .navbar-nav .nav-link:hover,
.sidebar .navbar-nav .nav-link.notActive:hover {
  border-right-color: var(--foundation-blue-light-active, #7ea5b6);
  background: var(--white-01-color);
  color: var(--main-01-color);
}

.sidebar .navbar-nav .nav-link.notActive {
  border: none !important;
  background: none;
  color: var(--white-04-color);
}

/* For Webkit browsers */
.sidebar::-webkit-scrollbar {
  width: 5px; /* width of the scrollbar */
}

/* Track */
.sidebar::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the track */
}

/* Handle */
.sidebar::-webkit-scrollbar-thumb {
  background: #88888849; /* color of the handle */
}

.sidebar .navbar-nav .nav-item-bottom {
  border-top: 1px solid #ffffff3a;
  margin-top: 15px;
}
.contnet-page {
  width: calc(100% - 280px);
  margin-right: 280px;
  overflow: hidden auto;
}

.contnet-page::-webkit-scrollbar {
  display: none;
}

.all-sidebar .navbar-nav .nav-item .icon-link-nav {
  width: 20px;
  height: 20px;
  transition: all 0.5s;
  margin: initial;
}

.all-sidebar.open .navbar-nav .nav-item .icon-link-nav {
  margin: auto;
}
.all-sidebar
  .navbar-nav
  :is(
    .nav-item:nth-child(1):hover .icon-link-nav,
    .nav-item:nth-child(1) .nav-link.active .icon-link-nav
  ) {
  background-image: url("../../../assets/images/dashboardImages/iconsSideBar/A01.svg") !important;
}

.all-sidebar
  .navbar-nav
  :is(
    .nav-item:nth-child(2):hover .icon-link-nav,
    .nav-item:nth-child(2) .nav-link.active .icon-link-nav
  ) {
  background-image: url("../../../assets/images/dashboardImages/iconsSideBar/A02.svg") !important;
}

.all-sidebar
  .navbar-nav
  :is(
    .nav-item:nth-child(3):hover .icon-link-nav,
    .nav-item:nth-child(3) .nav-link.active .icon-link-nav
  ) {
  background-image: url("../../../assets/images/dashboardImages/iconsSideBar/A03.svg") !important;
}

.all-sidebar
  .navbar-nav
  :is(
    .nav-item:nth-child(4):hover .icon-link-nav,
    .nav-item:nth-child(4) .nav-link.active .icon-link-nav
  ) {
  background-image: url("../../../assets/images/dashboardImages/iconsSideBar/A04.svg") !important;
}

.all-sidebar
  .navbar-nav
  :is(
    .nav-item:nth-child(5):hover .icon-link-nav,
    .nav-item:nth-child(5) .nav-link.active .icon-link-nav
  ) {
  background-image: url("../../../assets/images/dashboardImages/iconsSideBar/A05.svg") !important;
}

.all-sidebar
  .navbar-nav
  :is(
    .nav-item:nth-child(6):hover .icon-link-nav,
    .nav-item:nth-child(6) .nav-link.active .icon-link-nav
  ) {
  background-image: url("../../../assets/images/dashboardImages/iconsSideBar/A05.svg") !important;
}

.all-sidebar
  .navbar-nav
  :is(
    .nav-item:nth-child(7):hover .icon-link-nav,
    .nav-item:nth-child(7) .nav-link.active .icon-link-nav
  ) {
  background-image: url("../../../assets/images/dashboardImages/iconsSideBar/A06.svg") !important;
}

.all-sidebar
  .navbar-nav
  :is(
    .nav-item:nth-child(8):hover .icon-link-nav,
    .nav-item:nth-child(8) .nav-link.active .icon-link-nav
  ) {
  background-image: url("../../../assets/images/dashboardImages/iconsSideBar/A07.svg") !important;
}

.all-sidebar
  .navbar-nav
  :is(
    .nav-item:nth-child(9):hover .icon-link-nav,
    .nav-item:nth-child(9) .nav-link.active .icon-link-nav
  ) {
  background-image: url("../../../assets/images/dashboardImages/iconsSideBar/A08.svg") !important;
}

.all-sidebar
  .navbar-nav
  :is(
    .nav-item:nth-child(10):hover .icon-link-nav,
    .nav-item:nth-child(10) .nav-link.active .icon-link-nav
  ) {
  background-image: url("../../../assets/images/dashboardImages/iconsSideBar/A09.svg") !important;
}

.all-sidebar
  .navbar-nav
  :is(
    .nav-item:nth-child(11):hover .icon-link-nav,
    .nav-item:nth-child(11) .nav-link.active .icon-link-nav
  ) {
  background-image: url("../../../assets/images/dashboardImages/iconsSideBar/A010.svg") !important;
}

.all-sidebar
  .navbar-nav
  :is(
    .nav-item:nth-child(12):hover .icon-link-nav,
    .nav-item:nth-child(12) .nav-link.active .icon-link-nav
  ) {
  background-image: url("../../../assets/images/dashboardImages/iconsSideBar/A011.svg") !important;
}

.all-sidebar
  .navbar-nav
  :is(
    .nav-item:nth-child(13):hover .icon-link-nav,
    .nav-item:nth-child(13) .nav-link.active .icon-link-nav
  ) {
  background-image: url("../../../assets/images/dashboardImages/iconsSideBar/A012.svg") !important;
}

.all-sidebar
  .navbar-nav
  :is(
    .nav-item:nth-child(14):hover .icon-link-nav,
    .nav-item:nth-child(14) .nav-link.active .icon-link-nav
  ) {
  background-image: url("../../../assets/images/dashboardImages/iconsSideBar/A013.svg") !important;
}

.all-sidebar
  .navbar-nav
  :is(
    .nav-item:nth-child(15):hover .icon-link-nav,
    .nav-item:nth-child(15) .nav-link.active .icon-link-nav
  ) {
  background-image: url("../../../assets/images/dashboardImages/iconsSideBar/A014.svg") !important;
}

.all-sidebar
  .navbar-nav
  :is(
    .nav-item:nth-child(16):hover .icon-link-nav,
    .nav-item:nth-child(16) .nav-link.active .icon-link-nav
  ) {
  background-image: url("../../../assets/images/dashboardImages/iconsSideBar/A015.svg") !important;
}

.all-sidebar
  .navbar-nav
  :is(
    .nav-item:nth-child(17):hover .icon-link-nav,
    .nav-item:nth-child(17) .nav-link.active .icon-link-nav
  ) {
  background-image: url("../../../assets/images/dashboardImages/iconsSideBar/A016.svg") !important;
}

.all-sidebar
  .navbar-nav
  :is(
    .nav-item:nth-child(18) #dropdown-content .nav-link:nth-child(1):hover .icon-link-nav,
    .dropdown-content .nav-link:nth-child(1).active .icon-link-nav
  ) {
  background-image: url("../../../assets/images/dashboardImages/iconsSideBar/A017.svg") !important;
}

.all-sidebar
  .navbar-nav
  :is(
    .nav-item:nth-child(18) #dropdown-content .nav-link:nth-child(2):hover .icon-link-nav,
    .dropdown-content .nav-link:nth-child(2).active .icon-link-nav
  ) {
  background-image: url("../../../assets/images/dashboardImages/iconsSideBar/A018.svg") !important;
}
.all-sidebar
  .navbar-nav
  :is(
    .nav-item:nth-child(18) #dropdown-content .nav-link:nth-child(3):hover .icon-link-nav,
    .dropdown-content .nav-link:nth-child(3).active .icon-link-nav
  ) {
  background-image: url("../../../assets/images/dashboardImages/iconsSideBar/A019.svg") !important;
}

.all-sidebar
  .navbar-nav
  :is(
    .nav-item:nth-child(18) #dropdown-content .nav-link:nth-child(4):hover .icon-link-nav,
    .dropdown-content .nav-link:nth-child(4).active .icon-link-nav
  ) {
  background-image: url("../../../assets/images/dashboardImages/iconsSideBar/A020.svg") !important;
}

.all-sidebar
  .navbar-nav
  :is(
    .nav-item:nth-child(18) #dropdown-content .nav-link:nth-child(5):hover .icon-link-nav,
    .dropdown-content .nav-link:nth-child(5).active .icon-link-nav
  ) {
  background-image: url("../../../assets/images/dashboardImages/iconsSideBar/A021.svg") !important;
}

.all-sidebar .navbar-nav .btn-toggle {
  width: 100%;
  text-align: right;
  color: var(--white-04-color);
  font-size: 17px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: var(--border-06);
  border-bottom: var(--border-06);
  padding: 14px;
}
.all-sidebar .navbar-nav .btn-toggle .icon-arrow-btn :is(.icon-i) {
  transition: all 0.5s;
}
.all-sidebar .navbar-nav .btn-toggle.active .icon-arrow-btn :is(.icon-i) {
  transform: rotate(180deg);
}

/* DROPMENU SIDEBAR */
.sidebar-item {
  cursor: pointer;
  position: relative;
}

.sidebar-item .dropdown {
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  z-index: 1;
}

.sidebar-item .dropdown.open {
  height: auto;
}

.sidebar-item .dropdown-content {
  display: flex;
  flex-direction: column;
  transition: height 0.3s ease;
}
