.bg-step {
  background: var(--white-01-color);
  padding: 25px;
  border-radius: 16px;
  border: var(--border-06);
  position: relative;
}

.bg-step::before {
  content: "";
  width: 25px;
  height: 25px;
  background: var(--white-01-color);
  right: 35px;
  position: absolute;
  inset-block-start: -14px;
  inset-inline-end: 14px;
  transform: rotate(45deg);
  border: var(--border-06);
  border-bottom: 0;
  border-right: 0;
}

.all-steps-register-services #checkout-progress.step-2:after {
  -webkit-transform: scaleX(0.48) translateY(-50%) perspective(1000px);
  transform: scaleX(0.48) translateY(-50%) perspective(1000px);
}
#checkout-progress.step-3:after {
  -webkit-transform: scaleX(0.999) translateY(-50%) perspective(1000px);
  transform: scaleX(0.999) translateY(-50%) perspective(1000px);
}

.select-reg {
  margin-top: 11.5px;
}

.select-reg select {
  padding: 13px;
  font-size: 15px;
  color: var(--gray-01-color) !important;
  font-weight: 600;
}

.select-wrapper {
  position: relative;
}

.select-error {
  border: 1px solid red;
  outline: none;
  transition: border-color 0.15s ease-in-out;
}
.select-success {
  border-color: green;
  transition: border-color 0.15s ease-in-out;
}

.all-step-two .select-location :is(iframe, .iframe-map) {
  height: 250px;
}

.spinner-border {
  --bs-spinner-width: 1.5rem;
  --bs-spinner-height: 1.5rem;
}

#checkout-progress .progress-bar .step .step-label {
  font-weight: 700;
  font-size: 14px;
}


@media screen and (max-width: 767px) {
  #checkout-progress .progress-bar .step .step-label {
    font-size: 14px;
  }
  
}

@media screen and (max-width: 850px) and (max-width: 650px) {
  #checkout-progress .progress-bar .step .step-label {
    font-size: 12px;
  }
}
@media screen and (max-width: 600px) {
  #checkout-progress .progress-bar .step:nth-child(1) .step-label {
    transform: translateX(-88%);
  }
  

  #checkout-progress .progress-bar .step:nth-child(3) .step-label {
    transform: translateX(-10%);
  }
 
}
@media screen and (max-width: 460px){
  
.all-steps-register-services  .step:nth-child(2) .step-label{
  top:-30px !important;
}
}
@media screen and (max-width: 450px) {
  #checkout-progress .progress-bar .step:nth-child(1) .step-label {
    transform: translateX(-88%);
  }

 
  #checkout-progress .progress-bar .step:nth-child(3) .step-label {
    transform: translateX(-10%);
  }
  
  .bg-step {
    padding: 15px;
  }
  .bg-step .form-label {
    font-size: 13px;
  }
  .bg-step input::placeholder {
    font-size: 13px;
  }
  .bg-step .form-check .form-check-label {
    font-size: 12px;
    font-weight: 700;
    line-height: 1.8;
  }
}

@media screen and (max-width: 330px) {
  #checkout-progress .progress-bar .step:nth-child(1) .step-label,
  #checkout-progress .progress-bar .step:nth-child(3) .step-label {
    font-size: 10px;
  }
}

@media screen and (max-width: 318px) {
  .header-info-top-1 {
    padding-bottom: 30px;
  }
  .header-info-top-1 .all-header-page-title {
    padding: 30px 0 0 0;
  }
  .header-info-top-1 .link-back {
    padding-right: 10px;
  }
 
}
