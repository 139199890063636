.icon-social-login .icon-social-one {
  border: var(--border-06);
  width: 50px;
  height: 65px;
  padding: 0 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  transition: all 0.5s;
  cursor: pointer;
}

.icon-social-login .icon-social-one:hover {
  border-color: var(--main-01-color);
}

.bottom-form .title {
  font-size: 17px;
  font-weight: 600;
  color: var(--gray-01-color);
}
.bottom-form .link-form-register {
  padding: 0 !important;
}

@media screen and (max-width: 340px) {
  .icon-social-login .icon-social-one {
    padding: 0 35px;
  }
  .bottom-form {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 300px) {
  .icon-social-login {
    flex-wrap: wrap;
  }
  .icon-social-login .icon-social-one {
    padding: 15px 30px;

    height: 100%;
  }
}
