.custom-toggle-switch {
  display: flex;
  align-items: center;
  gap: 10px;
}

.custom-toggle-switch input[type="checkbox"] {
  display: none;
}

.custom-toggle-switch label {
  cursor: pointer;
  width: 50px;
  height: 25px;
  background-color: grey;
  border-radius: 25px;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.custom-toggle-switch input[type="checkbox"]:checked + label {
  background-color: var(--main-01-color);
}

.custom-toggle-switch label::after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  width: 19px;
  height: 19px;
  background-color: white;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.custom-toggle-switch input[type="checkbox"]:checked + label::after {
  left: 28px;
}
