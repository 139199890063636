.otp-page .all-input-otp {
  margin-top: 44px !important;
}
.otp-page input {
  padding: 19px 0 !important;
  text-align: center !important;
  font-size: 20px;
  font-weight: 600;
}

.otp-form .timer-resend {
  font-size: 20px;
  font-weight: 600;
  color: var(--main-01-color);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;
}

.otp-page .all-input-otp input {
  text-align: center !important;
}
