.main-privacy-dash .title {
  font-size: 25px;
  padding-top: 25px !important;
}

@media screen and (max-width: 450px) {
  .privacy-content .title {
    font-size: 20px;
  }

  .privacy-content .text {
    font-size: 14px;
  }
}
