.achievements-info {
  padding: 70px 0;
}
.achievements-info .counter-one {
  text-align: initial !important;
}

.achievements-info .section-title-landing .title {
  font-size: 29px;
}
.achievements-info .section-title-landing .section-title {
  text-align: initial !important;
}

.achievements-info
  .counter-home
  .all-counter
  .counter-one
  :is(.text-counter, .counter-num) {
  color: var(--main-01-color);
  gap: 0 !important;
}

.achievements-info .counter-home .all-counter .counter-one .icon-plus {
  font-size: 20px;
  font-weight: 700;
}

@media screen and (max-width: 450px) {
  .achievements-info .section-title-landing .title {
    font-size: 22px;
  }
}
