.footer .follow-news .title {
    font-size: 20px;
    font-weight: 600;
    color: var(--white-01-color);
  }
  .footer .follow-news .list-social {
    padding-top: 15px !important;
  }
  .footer .follow-news .list-social .list-one .icon-social {
    width: 40px;
    height: 40px;
    font-size: 17px;
    color: var(--white-01-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: var(--border-01);
  
    cursor: pointer;
    transition: all 0.5s;
  }