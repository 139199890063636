.account-info-details .all-basic-info .header-info-profile .main-info-one .text{
    font-size: 17px;
}
.account-info-details .all-basic-info .header-info-profile .main-info-one .title{
    font-size: 18px;
}

@media screen and (max-width:450px) {
    .account-info-details .all-basic-info .header-info-profile .all-info-content {
        flex-direction: column;
    }
}


@media screen and (max-width:320px) {
    .account-info-details .all-basic-info .header-info-profile .main-info-one .text{
        font-size: 15px;
    }
}

.date-birth{
    margin-top: 12px;
}
.date-birth .react-datepicker-wrapper {
width: 100%;
}
.date-birth .input-date-info {
    width: 100% !important;
    
}