.basic-information-content .all-show-rates .main-info-one {
  width: 15% !important;
}

@media screen and (max-width: 830px) {
  .basic-information-content .all-show-rates .main-info-one {
    width: 25% !important;
  }
}

@media screen and (max-width: 550px) {
  .basic-information-content .all-show-rates .main-info-one {
    width: 35% !important;
  }
}
@media screen and (max-width: 450px) {
  .basic-information-content .all-show-rates .main-info-one {
    width: 100% !important;
  }

  .basic-information-content .all-show-rates .all-info-content {
    flex-direction: row !important;
  }
}

/* START TABS RATE */
.tabs-rates-content .nav-link {
  font-size: 17px;
  font-weight: 600;
  color: var(--gray-01-color);
}
.tabs-rates-content .nav-link {
  border: none;
}

.tabs-rates-content .nav-link.active {
  border-bottom: 2px solid var(--main-01-color);
  color: var(--main-01-color);
}

/* END TABS RATE */
