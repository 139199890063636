/* START HEADER CARDS POS */
.all-cards-header-pos .info-details-booking-one {
  flex-wrap: nowrap !important;
  padding: 25px 10px;
}
.all-cards-header-pos .info-details-booking-one .num-booking {
  font-size: 17px;
  font-weight: 700;
}
.all-cards-header-pos .info-details-booking-one .text {
  font-size: 14px;
}

.all-cards-header-pos .info-details-booking-one .icon-booking {
  width: 48px;
  height: 48px;
}

@media screen and (max-width: 1199px) {
  .all-cards-header-pos .info-details-booking-one {
    padding: 25px 15px;
  }
  .all-cards-header-pos .info-details-booking-one .num-booking {
    font-size: 20px;
  }
  .all-cards-header-pos .info-details-booking-one .text {
    font-size: 16px;
  }

  .all-cards-header-pos .info-details-booking-one .icon-booking {
    width: 55px;
    height: 55px;
  }
}
/* END HEADER CARDS POS */

/* START FILTER BUTTONS */
.main-filter-category .title-top-1 {
  font-size: 22px;
  font-weight: 600;
  color: var(--dark-01-color);
  padding-bottom: 15px !important;
}

.main-filter-category .all-filter-buttons {
  max-width: 100%;
  overflow-x: auto;
  padding: 15px 0;
}
.main-filter-category .all-filter-buttons .main-btn {
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 700;
  min-width: max-content;
}

.card-product-category {
  border: var(--border-06);
  padding: 10px;
  border-radius: 12px;
  background: var(--white-01-color);
  transition: all 0.5s;
}
.card-product-category:hover {
  border-color: var(--main-01-color);
}

.card-product-category .info-content {
  padding: 15px 0 0 0;
}

.card-product-category .img-product img {
  width: 100%;
  height: 100px;
  border-radius: 12px;
  object-fit: cover;
}
.card-product-category .info-content :is(.title, .bottom-details-pro .price-product),
.details-order .title {
  font-size: 18px;
  font-weight: 600;
  color: var(--dark-01-color);
}
.card-product-category
  .info-content
  :is(.time-add, .time-add .text-time, .bottom-details-pro .price-product),
.details-order :is(.price, .num-info) {
  font-size: 17px;
  font-weight: 600;
  color: var(--gray-01-color);
}
.card-product-category .info-content .text-time {
  padding: 12px 0 !important;
}

.main-btn.main-counter-button {
  padding: 7px 12px;
  font-size: 14px;
  margin: 0;
}

.counter-product .num-counter {
  width: 100%;
  padding: 0 !important;
  max-width: 20px;
  border: none;
  text-align: center !important;
  font-weight: 600;
  background: transparent;
}
.card-product-category .info-content .bottom-details-pro {
  padding-top: 10px;
}
.counter-product .main-btn {
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 50%;
  font-size: 12px;
}

@media screen and (max-width: 767px) {
  .card-product-category .img-product img {
    height: 160px;
  }
}
/* END FILTER BUTTONS */
/* START DETAILS ORDER */
.details-order {
  background: #fafafa;
  padding: 15px;
  border-radius: 12px;
  border: var(--border-06);
}
.details-order .title-top {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-01-color);
}
.details-order .select-one-content .form-select {
  padding: 12px;
  font-size: 16px;
  font-weight: 600;
  color: var(--gray-01-color);
}
.details-order .select-one-content {
  position: relative;
}
.details-order .main-select-option-1 .select-one-content .error-message {
  position: absolute;
}
.details-order .main-btn.active,
.main-filter-category .all-filter-buttons .main-btn.active {
  background: var(--main-01-color);
  color: var(--white-01-color);
}
/* END DETAILS ORDER */

/* custom dropdown  */
.custom-dropdown {
  position: relative;
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  color: var(--dark-01-color);
  background: var(--white-01-color);
}

/* Styles for the selected option */
.custom-dropdown .selected-option {
  padding: 13px;
  border: var(--border-06);
  border-radius: 4px;
  cursor: pointer;
}

.custom-dropdown .options {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: var(--white-01-color);
  border: var(--border-06);
  border-top: 0;
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 1;
}

.custom-dropdown .option {
  padding: 10px;
  cursor: pointer;
  transition: all 0.5s;
  font-size: 15px;
  font-weight: 600;
  color: var(--gray-01-color);
  border-bottom: var(--border-06);
}
.custom-dropdown .option:hover {
  background: var(--main-01-color);
  color: var(--white-01-color);
}

.custom-dropdown.open .selected-option {
  border-radius: 4px 4px 0 0;
}
.custom-dropdown.open .options {
  display: block !important;
}
