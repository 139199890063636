.section-title-landing {
  text-align: center;
}

.section-title-landing .title-top {
  font-size: 18px;
  font-weight: 600;
  color: var(--main-01-color);
  padding-bottom: 12px !important;
}

.section-title-landing .section-title .title {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.8;
}

.section-title-landing .section-title .text {
  font-size: 16px;
  max-width: 700px;
  margin: auto !important;
  line-height: 1.8;
}

@media screen and (max-width: 767px) {
  .section-title-landing .section-title .text {
    max-width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .section-title-landing .title-top {
    font-size: 15px;
    font-weight: 700;
  }

  .section-title-landing .section-title .title {
    font-size: 17px;
  }
  .section-title-landing .section-title .text {
    font-size: 14px;
  }
}
