.all-tabs-content .nav-tabs {
  border: none;
  gap: 15px;
  padding-bottom: 25px;
}
.all-tabs-content .nav-tabs .nav-link {
  padding: 35px;
  border: var(--border-05);
  border-radius: 10px;
  font-size: 20px;
  font-weight: 600;
  width: 150px;
  color: var(--gray-01-color);
}
.all-tabs-content .nav-tabs .nav-link.active {
  background: var(--main-01-color);
  border-color: var(--main-01-color);
  color: var(--white-01-color);
}

/* SCREEN 545PX */
@media screen and (max-width: 545px) {
  .all-tabs-content .nav-tabs .nav-link {
    width: 118px;
    padding: 20px;
  }
}

/* SCREEN 425PX */
@media screen and (max-width: 425px) {
  .all-tabs-content .nav-tabs .nav-link {
    width: 100%;
    padding: 12px 20px;
  }
}

/* SCREEN 327PX */
@media screen and (max-width: 327px) {
  .all-tabs-content .nav-tabs .nav-link {
    padding: 12px 16px;
    font-size: 15px;
  }
}
