.contact .img-contact img {
  border-radius: 16px;
}
.contact .form-inputs {
  margin-left: 20px;
}
.contact .form-inputs .header-contact .title {
  font-size: 28px;
  font-weight: 600;
  color: var(--dark-02-color);
  padding-bottom: 12px !important;
}

.contact .form-inputs .header-contact .text {
  font-size: 17px;
  font-weight: 500;
  color: var(--gray-01-color);
  line-height: 1.8;
  padding-bottom: 12px !important;
}

@media screen and (max-width:767px) {
    .contact .img-contact img {
        height: 300px !important;
      }
      .contact .form-inputs {
        margin-left: 0px;
        margin-top: 25px;
      }
}


@media screen and (max-width:450px) {
    .contact .img-contact img {
        height: 100% !important;
      }
      
}
