.header .slider-header {
  padding: 50px 0 200px 0;
}
.header .slider-header .slider-card .info-slider .head-title {
  font-size: 28px;
  font-weight: 600;
  color: var(--red-dark-01-color);
  padding: 0 0 15px 0 !important;
}

.header .slider-header .slider-card .info-slider .title {
  font-size: 45px;
  font-weight: 600;
  line-height: 1.6;
  color: var(--dark-02-color);
  padding-bottom: 25px !important;
}

.shape-img {
  top: -78px !important;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  .header .slider-header .slider-card .info-slider .title {
    font-size: 34px;
  }
}
@media screen and (max-width: 450px) {
  .header .slider-header .slider-card .info-slider .title {
    font-size: 26px;
  }
}

.image-anim {
  animation: anim1 2s infinite alternate;
}

@keyframes anim1 {
  from {
    transform: translateY(-15px);
  }
  to {
    transform: translateY(0);
  }
}

/* OWL */

.slider-header .owl-theme .owl-dots .owl-dot span {
  width: 45px;
  height: 6px;
}

.slider-header .owl-theme .owl-dots .owl-dot.active span {
  background: var(--main-01-color);
}
.slider-header .owl-theme .owl-nav :is(button.owl-prev, button.owl-next) {
  font-size: 40px;
  width: 40px;
  height: 40px;
  background: #d6d6d6 !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}

.slider-header .owl-theme .owl-nav :is(button.owl-prev, button.owl-next) span {
  transform: translateY(-4px);
}

.slider-header .owl-theme .owl-nav [class*="owl-"]:hover {
  background: var(--main-01-color) !important;
  color: var(--white-01-color) !important;
}

.slider-header .owl-theme .owl-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  transform: translateY(32px);
  margin: 0;
  position: absolute;
  bottom: -90%;
  width: 100%;
  right: -25%;
  z-index: 100;
}
.slider-header .owl-theme .owl-nav button.owl-prev {
  margin-right: -55px;
}

.slider-header .owl-theme .owl-dots {
  position: absolute;
  bottom: -90%;
  width: 100%;
  transform: translateY(100%);
  right: -25%;
  z-index: 95;
}


.slider-header .owl-theme .owl-dots .owl-dot:nth-child(1) span {
  border-radius: 10px 0 0 10px;
}
.slider-header .owl-theme .owl-dots .owl-dot:last-child span {
  border-radius: 0 10px 10px 0;
}
.slider-header .owl-theme .owl-dots .owl-dot span {
  margin: 5px 0;
  border-radius: 0;
}

@media screen and (max-width: 1199px) {
  .slider-header .owl-theme .owl-nav,
  .slider-header .owl-theme .owl-dots {
    bottom: -113%;
    right: -18%;
  }

  .slider-header .owl-theme .owl-nav button.owl-prev {
    margin-right: 55px;
  }
}

@media screen and (max-width: 991px) {
  .slider-header .owl-theme .owl-nav,
  .slider-header .owl-theme .owl-dots {
    bottom: -85%;
    right: -8%;
  }
 
  .slider-header .owl-theme .owl-nav button.owl-prev {
    margin-right: 50%;
  }
}

@media screen and (max-width: 767px) {
  .slider-header .owl-theme :is(.owl-dots, .owl-nav) {
    position: relative;
    bottom: initial;
    right: initial;
  }

  .slider-header .owl-theme .owl-nav button.owl-prev {
    margin-right: 14%;
  }

  .slider-header .owl-theme .owl-nav {
    transform: translateY(-10px);
  }
  .slider-header .owl-theme .owl-dots {
    transform: translateY(-44px);
  }
}

@media screen and (max-width: 513px) {
  .slider-header .owl-theme .owl-nav,
  .slider-header .owl-theme .owl-dots {
    position: relative;
    bottom: initial;
    right: initial;
  }
  .slider-header .owl-theme .owl-nav button.owl-prev {
    margin-right: 35%;
  }
}

@media screen and (max-width: 450px) {
  .slider-header .owl-theme .owl-nav button.owl-prev {
    margin-right: 55%;
  }
  .slider-header .owl-theme .owl-dots .owl-dot span {
    width: 40px;
  }
}

@media screen and (max-width: 359px) {
  .slider-header .owl-theme .owl-nav button.owl-prev {
    margin-right: 62%;
  }
  .slider-header .owl-theme .owl-dots .owl-dot span {
    width: 35px;
  }
}

@media screen and (max-width: 320px) {
  .slider-header .owl-theme .owl-nav {
    display: none;
  }
  .slider-header .owl-theme .owl-dots {
    transform: translateY(-7px);
  }
  .slider-header .owl-theme .owl-dots .owl-dot span {
    width: 50px;
  }
}

@media screen and (max-width: 275px) {
  .slider-header .owl-theme .owl-dots .owl-dot span {
    width: 45px;
  }
}
