


/* ======= START TABLE DATA ======= */
.table-data-info {
    background: var(--gray-04-color);
    padding: 15px;
    border: var(--border-03);
    border-radius: 12px;
  }
  .table-data-info .title {
    font-size: 20px;
    font-weight: 600;
    color: var(--dark-01-color);
    padding-bottom: 18px !important;
  }
  .table-data-info .table > :not(caption) > * > * {
    background: transparent;
    color: initial;
  }
  .table-data-info .table {
    background: var(--main-01-color);
    border-radius: 12px;
  }
  .table-data-info tbody {
    background: #fff;
  }
  .table-data-info thead tr th,
  .table-data-info td {
    font-size: 17px;
    font-weight: 600;
    color: var(--white-02-color) !important;
    padding: 12px;
    vertical-align: middle;
  }
  .table-data-info td {
    color: var(--gray-01-color) !important;
  }
  
  .table-data-info :is(.hash-id, tbody th) {
    padding: 12px 20px 12px 0;
    vertical-align: middle;
  }
  
  html[dir="ltr"] .table-data-info :is(.hash-id, tbody th){
    padding: 12px 0px 12px 20px;
  }

  @media screen and (max-width:437px) {
    .table-content-2 .table{
        width: 800px !important;
    }
}
  /* ======= END TABLE DATA ======= */