.navbar-header .navbar {
  padding: 12px 0;
}
.navbar .navbar-nav .nav-link {
  font-size: 16px;
  font-weight: 600;
  color: var(--gray-01-color);
  margin-left: 12px;
}

.navbar .navbar-nav .nav-link.active {
  color: var(--main-01-color);
}

.navbar .lang {
  cursor: pointer;
}

.navbar .menu-toggle-lang .info-login-navbar {
  display: none !important;
}

.menu-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(21, 20, 20, 0.1);
  transition: all 0.3s ease-in-out;
}

/* Keyframes for the animation */
@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
/* SCREEN 991PX */
@media screen and (max-width: 991px) {
  .navbar .menu-toggle-lang .info-login-navbar {
    display: flex !important;
  }
  .navbar .main-nav-left .info-login-navbar {
    display: none !important;
  }
  .navber-header {
    padding: 0px 0;
  }

  .navbar-toggler:focus {
    box-shadow: none !important;
    border-color: var(--main-01-color);
  }
  .navbar .main-nav-left {
    padding: 0 15px 15px 0;
  }

  .navbar .navbar-collapse {
    background: #f6f6f6;
    border-radius: 8px;
  }
  .navbar .navbar-collapse .navbar-nav {
    padding: 15px;
  }
  .navbar .navbar-collapse .navbar-nav .nav-link {
    font-size: 20px;
    font-weight: 600;
  }
}

/* SCREEN 550PX */
@media screen and (max-width: 550px) {
  .navbar .main-nav-left {
    padding: 0 15px 15px 15px;
  }
  .navbar .main-nav-left :is(.main-buttons, .main-btn) {
    width: 100%;
  }
}

/* SCREEN 377PX */
@media screen and (max-width: 397px) {
  .navbar .main-nav-left .main-btn {
    font-size: 14px;
    font-weight: 700;
  }
}

/* SCREEN 350PX */
@media screen and (max-width: 368px) {
  .navbar .main-nav-left .main-btn {
    font-size: 12px;
  }
}
/* SCREEN 339PX */
@media screen and (max-width: 339px) {
  .navbar .main-nav-left .main-buttons {
    flex-wrap: wrap;
    gap: 8px !important;
  }
  .navbar .main-nav-left .main-btn {
    font-size: 15px;
  }
}

@media screen and (max-width: 295px) {
  .navbar .navbar-brand img {
    width: 80px;
  }
  .menu-toggle-lang .navbar-toggler {
    padding: 5px;
  }
  .menu-toggle-lang .navbar-toggler .navbar-toggler-icon {
    width: 1.1em;
    height: 1.1em;
  }
}

/* LOGO ANIMATION */
.logo-all {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo-all .logo {
  transition: transform 0.3s ease, opacity 0.3s ease;
  position: absolute;
  top: -13px;
  transform: translateX(-50px);
}

.logo-all .logo.hidden {
  opacity: 0;
  transform: translate(-50px, 10px);
}
