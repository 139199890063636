.footer {
  background: var(--dark-01-color);
  padding-top: 70px;
}

.footer .text {
  font-size: 15px;
  font-weight: 500;
  color: var(--white-02-color);
  line-height: 1.8;
  padding: 20px 0 0 0 !important;
}


.footer .footer-one .title {
  font-size: 20px;
  font-weight: 600;
  color: var(--white-01-color);
  padding-bottom: 12px !important;
}
.footer .footer-one .list-link .nav-link {
  font-size: 15px;
  font-weight: 600;
  color: var(--white-02-color);
  padding: 10px 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer .final-footer {
  padding: 15px 0;
  margin-top: 50px;
  border-top: var(--border-01);
  border-color: var(--gray-01-color);
}
.footer .final-footer .text-footer {
  font-size: 16px;
  font-weight: 600;
  color: var(--white-01-color);
}

@media screen and (max-width: 450px) {
  .footer .text {
    font-size: 13px;
  }
}

@media screen and (max-width: 360px) {
  .footer .final-footer .text-footer {
    font-size: 14px;
  }
  .footer .follow-news .title {
    font-size: 18px;
  }
}

@media screen and (max-width: 340px) {
  .footer-content .text-footer {
    font-size: 12px;
  }
}

