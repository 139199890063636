.report-details-container {
  min-height: 100%;
  padding: 1rem;
}

.report-details-content {
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
}

.report-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.back-button {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
  border: 2px solid #0c336e;
  border-radius: 8px;
  background-color: transparent;
  color: #0c336e;
  font-weight: 600;
  transition: all 0.2s ease;
}

.back-button:hover {
  background-color: #0e274c;
  color: white;
}

.report-title {
  font-size: 1.5rem;
  color: #2c3e50;
  font-weight: 700;
  margin: 0;
}

.report-body {
  padding: 2rem;
}

/* Table styles */
.report-table {
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
}

.report-table h3 {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-family: "Cairo", sans-serif;
}

th,
td {
  text-align: right;
  padding: 0.8rem;
  border-bottom: 1px solid #eee;
}

th {
  background-color: #f8f9fa;
  font-weight: bold;
}

tr:last-child td {
  border-bottom: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .report-actions {
    flex-direction: row;
    align-items: flex-start;
  }

  .search-form {
    width: 100%;
  }
  .report-actions {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  .back-button,
  .search-btn,
  .export-btn {
    transition: none;
  }
}

.report-summary {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 1rem;
  margin-bottom: 20px;
}

.summary-card {
  border-radius: 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  flex: 1;
  min-width: 200px;
  max-width: calc(33.333% - 20px);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  /* transition: all 0.3s ease; */
  cursor: pointer;
  border: 1px solid #e0e0e0;
}

.summary-card .icon {
  font-size: 24px;
  color: #333;
  margin-left: 2rem;
}

.summary-card .card-content {
  display: flex;
  flex-direction: column;
  text-align: right;
  flex-grow: 1;
}

.card-content h3 {
  font-size: 24px;
  margin: 0;
}

.card-content p {
  font-size: 14px;
  margin: 5px 0 0;
  color: #666;
}

@media (max-width: 768px) {
  .summary-card {
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .summary-card {
    max-width: 100%;
  }
  .report-summary {
    margin-top: 0.5rem;
  }

  .report-table {
    margin-top: 0.5rem;
  }
}

.employees-report {
  padding: 20px;
  margin-top: 1rem;
}

.report-summary {
  margin-bottom: 20px;
}

.summary-card {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* width: fit-content; */
  justify-content: space-between;
}

.summary-card .card-content {
  display: flex;
  flex-direction: column;
}

.summary-card .icon {
  font-size: 1.5rem;
  color: #333;
}

.card-content {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-content h3 {
  font-size: 24px;
  margin: 0;
  color: #333;
}

.card-content p {
  font-size: 14px;
  margin: 5px 0 0;
  color: #666;
}

.report-table {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.report-table h3 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 18px;
  color: #333;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  text-align: right;
  padding: 12px;
  border-bottom: 1px solid #e0e0e0;
}

th {
  font-weight: bold;
  color: #333;
  background-color: #f5f5f5;
}

tr:last-child td {
  border-bottom: none;
}

.financial-report {
  padding: 20px;
}

.financial-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.financial-card {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.financial-card .icon {
  font-size: 24px;
  margin-right: 15px;
  color: #333;
}

.card-content {
  text-align: right;
}

.card-content h3 {
  font-size: 24px;
  margin: 0;
  color: #333;
}

.card-content p {
  font-size: 14px;
  margin: 5px 0 0;
  color: #666;
}

.report-filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.8rem;
  align-items: center;
  flex-grow: 1;
  padding: 1rem;
}

.filter-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.filter-group select,
.filter-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 0.9rem;
  background-color: #fff;
  margin-bottom: 0.5rem;
}

.report-actions {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.export-btn {
  padding: 0.7rem 1rem;
  background-color: #0d3b66;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Cairo", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  width: auto;
}

.search-btn {
  padding: 0.7rem 1rem;
  background-color: #0d3b66;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0rem 1rem;
  font-family: "Cairo", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  width: auto;
}

.search-btn:hover {
  background-color: #0d3b66;
  color: white;
}

.select-reg {
  margin-top: 11.5px;
}

.select-reg select {
  padding: 10px;
  font-size: 15px;
  border: 1px solid #e0e0e0;
  color: var(--gray-01-color);
  font-weight: 600;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: none;
}
/* 
.form-select {
  padding: 0.5rem 1.5rem;
  font-size: 12px;
  color: var(--gray-01-color);
  font-weight: 600;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  width: 100%;
} */

/* For IE */
.form-select::-ms-expand {
  display: none;
}

.select-reg .form-select {
  width: 100%;
}

.select-reg .form-select option {
  font-size: 12px;
  color: var(--gray-01-color);
  font-weight: 600;
}

.select-reg .form-select:focus {
  border-color: #010508;
  box-shadow: 0 0 0 0.2rem rgba(13, 59, 102, 0.25);
}
