.landing-bg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  padding: 100px 0;
}
.landing-bg .info-hero {
  text-align: center;
  width: 100%;
  max-width: 700px;
  margin: auto;
}
.landing-bg .info-hero :is(.title-head, .text) {
  font-size: 20px;
  font-weight: 600;
  color: #475569;
  padding-bottom: 12px !important;
}
.landing-bg .info-hero .title {
  font-size: 35px;
  font-weight: 700;
  color: var(--dark-01-color);
  padding-bottom: 12px !important;
  line-height: 1.8;
}

.landing-bg .info-hero .main-btn {
  margin: 15px auto 0 auto;
}

.landing-bg .show-video .text-play{
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-01-color);
}

/* SCREEN 737Px */
@media screen and (max-width: 767px) {
  .landing-bg .info-hero {
    max-width: 100%;
  }

  .landing-bg .info-hero .title {
    font-size: 28px;
  }
}
/* SCREEN 450Px */
@media screen and (max-width: 450px) {
  .landing-bg {
    padding: 50px 0;
  }

  .landing-bg .info-hero :is(.title-head, .text) {
    font-size: 15px;
  }
  .landing-bg .info-hero .title {
    font-size: 18px;
  }
}
