.all-cards-services {
  padding: 50px 0 100px 0;
}

.all-cards-services .badge-product-services {
  display: none;
}
.all-cards-services .button-more .main-btn {
  border-width: 1.5px;
}
