/* DateSelector.css */

.date-selector {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.date-item {
  flex: 1;
  text-align: center;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  cursor: pointer;
  transition:
    background-color 0.3s,
    color 0.3s;
}

.date-item:hover {
  background-color: #e0e0e0;
  color: #1f4a79;
  border: 2px solid #1f4a79;
  border-radius: 4px;
  transition: background-color 0.83s ease-in-out;
}

.selected {
  background-color: #1f4a79;
  color: white;
  border: 2px solid #1f4a79;
  border-radius: 4px;
  transition: background-color 0.83s ease-in-out;
}

.day-number {
  font-size: 1.5em;
}

.day-label {
  font-size: 0.9em; /* Smaller font size for day label */
  color: #555; /* Dark gray color for day label */
}

.button-one.disabled {
  pointer-events: none;
  background-color: #f5f5f5;
}

.time-slots-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  gap: 8px;
  padding: 10px;
  min-height: 100px;
}

.time-slot-button {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 8px 4px;
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
}

.time-slot-button:hover:not(.disabled) {
  border-color: var(--main-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.time-slot-button.active {
  background: var(--main-color);
  color: var(--main-color-text);
  border-color: var(--main-color);
  cursor: pointer;
  opacity: 1;
  transition: all 0.3s ease;
}

.time-slot-button.disabled {
  background: #f5f5f5;
  cursor: not-allowed;
  opacity: 0.5;
}

.time-text {
  font-size: 14px;
  margin-bottom: 4px;
}

.icon-check {
  font-size: 0.8rem;
}

.pagination-container {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 24px;
}

.pagination-button {
  min-width: 36px;
  height: 36px;
  padding: 0 12px;
  border: 1px solid #e0e0e0;
  background: #fff;
  color: #666;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.pagination-button:hover:not(:disabled) {
  background: #f5f5f5;
  border-color: #ccc;
  color: #333;
}

.pagination-button:disabled {
  background: #f9f9f9;
  border-color: #eee;
  color: #ccc;
  cursor: not-allowed;
  box-shadow: none;
}

.pagination-button.active {
  background: var(--main-color);
  color: var(--main-color-text);
  border-color: var(--main-color);
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.pagination-button.active:hover {
  background: var(--main-color);
  color: white;
  opacity: 0.9;
}

.time-slot-button .text-danger {
  font-size: 0.8rem;
}

.time-select {
  padding: 16px 16px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  font-size: 14px;
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 16px 5px;
  color: #555;
  direction: ltr;
}

.time-select:hover {
  border-color: var(--main-color);
}

.time-select:focus {
  outline: none;
  border-color: var(--main-color);
  box-shadow: 0 0 0 1px var(--main-color);
}

.time-select option {
  padding: 16px;
  font-size: 14px;
  direction: ltr;
  margin: 16px 5px;
  height: 100px;
}

.time-select option:disabled {
  color: #999;
  background-color: #f5f5f5;
  text-align: right;
  direction: ltr;
}

.time-select option:disabled:hover {
  background-color: #f5f5f5;
  color: #999;
  cursor: not-allowed;
}

.time-select option:selected {
  background-color: #1f4a79;
  color: white;
}
