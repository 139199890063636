.card-booking-one {
  border: var(--border-06);
  padding: 15px;
  border-radius: 16px;
}
.card-booking-one .header-card-booking {
  border-bottom: var(--border-06);
  padding-bottom: 12px;
}
.card-booking-one :is(.text-date, .num-booking) {
  font-size: 20px;
  font-weight: 600;
  color: var(--gray-01-color);
}
.card-booking-one .info-card-booking {
  padding: 15px 0 0 0;
}
.card-booking-one .info-card-booking .title {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-01-color);
  line-height: 1.8;
}
.card-booking-one .info-card-booking .details-date {
  padding-top: 14px;
}

.badge-card {
  padding: 8px 30px;
  border-radius: 50px;
  font-size: 17px;
  font-weight: 500;
  color: var(--dark-01-color);
}

.badge-card.bg-01 {
  background: rgba(104, 132, 171, 0.1);
  color: #6884ab;
}
.badge-card.bg-02 {
  background: rgba(102, 102, 102, 0.1);
  color: #666666;
}

.badge-card.bg-03 {
  background: rgba(52, 168, 83, 0.1);
  color: #34a853;
}

.badge-card.bg-04 {
  background: rgba(156, 70, 81, 0.1);
  color: #9c4651;
}
.badge-card.bg-05 {
  background: rgba(190, 140, 77, 0.1);
  color: #be8c4d;
}

@media screen and (max-width: 450px) {
  .card-booking-one .info-card-booking .title {
    font-size: 17px;
  }
}
