.counter-home .all-counter .counter-one .counter-num {
  font-size: 34px;
  font-weight: 700;
  color: var(--dark-01-color);
}

.counter-home .all-counter .counter-one .text-counter {
  font-size: 18px;
  font-weight: 600;
  color: var(--gray-01-color);
}

.counter-home .all-counter .header-counter .counter-one .counter-num {
  font-size: 40px;
  font-weight: 700;
  background: linear-gradient(
      247.96deg,
      rgba(128, 208, 199, 1) 26.26%,
      rgba(19, 84, 122, 1) 93.62%
    ),
    linear-gradient(0deg, rgba(19, 84, 122, 1), rgba(128, 208, 199, 1));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.counter-home .all-counter .counter-one .icon-plus {
  background: transparent !important;
}

.counter-home .main-counter {
  padding: 25px 0;
}
