.header-top-content .left-info-search .form-search {
  width: 213px;
}

.wallets-container {
  width: 100%;
  padding: 20px;
}

.wallets-content {
  margin-top: 20px;
}

.header-top-content input {
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 12px 1rem;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

@media screen and (max-width: 751px) {
  .header-top-content {
    display: block !important;
    margin-top: 15px !important;
  }
  .header-top-content :is(.left-info-page-content, .left-info-search, .form-search) {
    width: 100% !important;
  }
}
