.chart-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.chart-title {
  margin: 0;
  font-size: 1.2rem;
}

.period-buttons {
  display: flex;
  gap: 10px;
}

.period-button {
  padding: 8px 16px;
  border: none;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 10px;
}

.period-button.active {
  background-color: #242153;
  color: #fff;
}

@media (max-width: 768px) {
  .chart-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .period-buttons {
    margin-top: 10px;
  }
}
