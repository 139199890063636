.profile-page-info-dash .all-info-profile-content .header-profile {
  margin-top: -100px;
  border: var(--border-03);
  border-radius: 50%;
  border-width: 2px;
}

.profile-page-info-dash .header-info-profile .icon-edit-pen {
  bottom: initial;
  top: 15px;
}

.profile-page-info-dash .header-info-profile .button-save {
  position: absolute;
  top: 15px;
  left: 15px;
}

.profile-page-info .bottom-basic-info .icon-edit-pen {
  position: relative;
  bottom: initial;
  top: initial;

  left: inherit;
}

.profile-page-info .bottom-basic-info .info-icon {
  font-size: 18px;
  font-weight: 600;
  color: var(--gray-01-color);
}
.check-input-one {
  font-size: 17px;
  font-weight: 600;
}
.check-input-one .icon-check {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--dark-01-color);
  background: var(--white-02-color);
}
@media screen and (max-width: 767px) {
  .profile-page-info-dash .header-info-profile .button-save {
    position: relative;
    top: 5px;
    left: 0;
  }
  .basic-information-content-dash .all-info-content .main-info-one {
    width: 40% !important;
  }

  .basic-information-content-dash .all-info-content .main-info-one:nth-child(2) {
    width: 100% !important;
  }
}

@media screen and (max-width: 480px) {
  .basic-information-content-dash .all-info-content {
    flex-wrap: wrap;
  }
  .basic-information-content-dash .all-info-content .main-info-one {
    width: 100% !important;
  }
}
@media screen and (max-width: 380px) {
  .profile-page-info .bottom-basic-info .info-icon,
  .profile-page-info-dash .check-input-one {
    font-size: 14px !important;
    font-weight: 700;
  }
}

@media screen and (max-width: 350px) {
  .profile-page-info .info-one-basic {
    flex-wrap: wrap;
  }
}

/* Social Media Section Styles */
.social-media-section {
  background-color: var(--white-01-color);
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
}

.social-media-section h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.social-input-group {
  display: flex;
  align-items: center;
  padding: 12px 10px;
  border-radius: 6px;
  margin-bottom: 15px;
}

.social-input-group .social-icon {
  color: var(--main-01-color);
  font-size: 16px;
  margin-left: 10px;
}

.social-input-group input {
  flex: 1;
  padding: 8px 12px;
  border: var(--border-03);
  border-width: 3px;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
}

.social-input-group input:focus {
  border-color: var(--main-01-color);
  outline: none;
}

.social-input-group .social-text {
  color: var(--gray-01-color);
  font-size: 14px;
  margin-left: 15px;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  .social-input-group {
    flex-wrap: wrap;
  }

  .social-input-group .social-text {
    width: 100%;
    margin-bottom: 10px;
  }

  .social-input-group input {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .social-media-section {
    padding: 15px;
  }
}
