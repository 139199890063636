.profile-page-info .img-bg {
  height: 350px;
  border-radius: 16px;
}

.profile-page-info .all-info-profile-content {
  background: var(--white-01-color);
  box-shadow: var(--box-shadow);
  border: var(--border-06);
  width: 100%;
  max-width: 900px;
  margin: auto;
  padding: 50px;
  margin-top: -150px;
  border-radius: 16px;
}

.profile-page-info .all-info-profile-content .header-profile {
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;
  width: fit-content;
}

.profile-page-info .all-info-profile-content .header-profile .icon-edit {
  width: 35px;
  height: 35px;
  background: var(--white-01-color);
  box-shadow: var(--box-shadow);
  color: var(--gray-01-color);
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.profile-page-info .tabs-profile {
  border: none;
  gap: 25px;
  padding: 30px 0 0 0;
}
.profile-page-info .tabs-profile .nav-link {
  font-size: 16px;
  font-weight: 600;
  color: var(--gray-01-color);
  border: none;
}
.profile-page-info .tabs-profile .nav-link.active {
  border-bottom: 2px solid var(--main-01-color);
  color: var(--main-01-color);
}

@media screen and (max-width: 991px) {
  .profile-page-info .all-info-profile-content {
    max-width: 95%;
  }
}
