.main-search {
  border: 2px solid var(--white-02-color);
  background: var(--white-01-color);
  padding: 15px 10px;
  border-radius: 12px;
}
.main-search .input-info input {
  border: none !important;
  box-shadow: none;
}

.main-search .input-info .icon {
  font-size: 20px;
  color: var(--gray-01-color);
  cursor: pointer;
}

.search-content .title-search {
  font-size: 20px;
  font-weight: 600;
  color: var(--gray-01-color);
  padding-bottom: 15px !important;
}

.search-content .form-input-one:not(:first-child) {
  border-right: var(--border-03);
  border-width: 2px;
}

.search-content .form-input-one:nth-child(2) .icon {
  margin-right: 10px;
}

.search-content .main-btn {
  padding: 12px 35px;
}

.main-search .form-input-one {
  flex: 1 0 10rem;
}
.main-search .main-btn {
  flex: 1 0 4rem;
}
@media screen and (max-width: 450px) {
  .main-search .form-input-one {
    flex: 100%;
  }
  .main-search .main-btn {
    flex: 100%;
  }
  .search-content .form-input-one:nth-child(2) .icon {
    margin: 0;
  }
  .search-content .form-input-one:not(:first-child) {
    border: none;
  }
}
