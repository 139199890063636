.package-offer-card {
  border: var(--border-06);
  border-radius: 16px;
  background: var(--white-01-color);
  padding: 20px;
  cursor: pointer;
  transition: all 0.5s;
}

.package-offer-card:hover {
  background: var(--main-01-color);
  transform: scale(1.03);
}

.package-offer-card .icon-star-card {
  font-size: 22px;
  color: var(--gray-01-color);
}
.package-offer-card .name-package {
  background: var(--white-02-color);
  padding: 10px 25px;
  border-radius: 8px;
  width: fit-content;
  font-size: 17px;
  font-weight: 600;
  color: var(--dark-01-color);
  margin: 15px 0;
}

.package-offer-card .text-package,
.package-offer-card .pricing-package .text-date {
  font-size: 17px;
  font-weight: 500;
  color: var(--gray-01-color);
  line-height: 1.8;
}

.package-offer-card .pricing-package {
  border-top: var(--border-06);
  border-bottom: var(--border-06);
  padding: 15px 0;
  margin: 15px 0;
}

.package-offer-card .pricing-package .num-price {
  font-size: 25px;
  font-weight: 600;
  color: var(--dark-01-color);
}
.package-offer-card .pricing-package .num-price .type-pay {
  font-size: 17px;
}
.package-offer-card .info-package-card .item-package-one {
  font-size: 15px;
  font-weight: 600;
  color: var(--dark-01-color);
}
.package-offer-card .info-package-card .item-package-one .icon-chaeck-pack {
  width: 25px;
  height: 25px;
  background: var(--gray-01-color);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white-01-color);
  font-size: 15px;
}

.package-offer-card .text-package-color {
  font-size: 17px;
  font-weight: 600;
  color: var(--red-dark-01-color);
  text-align: center;
}
.package-offer-card .badge-text-card {
  background: #9c465121;
  padding: 6px 10px;
  border-radius: 8px;
}
.package-offer-card .badge-text-card .text-package-color {
  font-size: 14px;
}

.package-offer-card
  :is(
    .icon-star-card,
    .text-package,
    .text-date,
    .pricing-package,
    .num-price,
    .item-package-one,
    .icon-chaeck-pack,
    .text-package-color,
    .badge-text-card,
    .icon-chaeck-pack,
    .main-btn
  ) {
  transition: all 0.5s;
}
.package-offer-card:hover
  :is(
    .icon-star-card,
    .text-package,
    .text-date,
    .pricing-package,
    .num-price,
    .item-package-one,
    .icon-chaeck-pack
  ) {
  color: var(--white-01-color);
}
.package-offer-card:hover .text-package-color {
  color: #fbbc05;
}
.package-offer-card:hover .badge-text-card {
  background: #fbbd0557;
}

.package-offer-card:hover
  .info-package-card
  .item-package-one
  .icon-chaeck-pack {
  background: var(--white-01-color);
  color: var(--dark-01-color);
}
.package-offer-card:hover .main-btn {
  background: var(--white-01-color);
  color: var(--dark-01-color);
  border-color: var(--white-01-color);
}

@media screen and (max-width: 767px) {
  .package-offer-card:hover {
    transform: scale(1.02);
  }
}

@media screen and (max-width: 450px) {
  .package-offer-card .info-package-card .item-package-one {
    font-size: 13px;
  }
  .package-offer-card .text-package {
    font-size: 14px;
  }
}
