.basic-information-content .all-details-branch .info-data-user .name-user {
  font-size: 22px;
  font-weight: 600;
  color: var(--dark-01-color);
}

.basic-information-content .all-details-branch .info-data-user .title {
  font-size: 18px;
  font-weight: 600;
  color: var(--gray-01-color);
}

.basic-information-content .all-details-branch .main-info-one {
  width: 32% !important;
}
.basic-information-content .bottom-details-branch .title {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-01-color);
}
.basic-information-content .bottom-details-branch .img-branch img {
  border-radius: 8px;
}

@media screen and (max-width: 917px) {
  .basic-information-content .all-details-branch .main-info-one {
    width: 42% !important;
  }
}

@media screen and (max-width: 717px) {
  .basic-information-content .all-details-branch .main-info-one {
    width: 50% !important;
  }
}

@media screen and (max-width: 450px) {
  .basic-information-content .all-details-branch .main-info-one {
    width: 100% !important;
  }
  .basic-information-content .all-details-branch .main-info-one .title {
    font-size: 15px !important;
    font-weight: 700 !important;
  }
  .basic-information-content .all-details-branch .main-info-one .text {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 335px) {
  .all-bottom-details .images-branches {
    display: block !important;
  }
  .bottom-details-branch .img-branch img {
    width: 100% !important;
    height: 100% !important;
    margin-bottom: 15px;
    object-fit: cover;
  }
}

/* START PAGE EDIT ADD BRANCHES */
.main-time-work {
  margin-top: 13px;
}
.map-loction-branch .title {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-01-color);
}
.map-loction-branch iframe {
  height: 300px !important;
}

/* END PAGE EDIT ADD BRANCHES */

