.accordion .accordion-item {
  margin-bottom: 25px;
  border-radius: 16px;
  border: var(--border-06);
}
.accordion .accordion-header .accordion-button {
  font-size: 17px;
  font-weight: 600;
  color: var(--dark-01-color);
  border: none;
  border-radius: 16px 16px 0 0;
  padding: 20px;
  line-height: 1.8;
}
.accordion-item .accordion-button.collapsed {
  border-radius: 16px;
}
.accordion-item .accordion-button::after,
.accordion-item .accordion-button:not(.collapsed)::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 20px;
  background: none;
  width: 32px;
  height: 32px;
  background: var(--white-02-color);
  color: var(--dark-01-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

.accordion-item .accordion-button::after {
  content: "\2b";
}

.accordion-item .accordion-button:not(.collapsed)::after {
  content: "\f068";
}

.accordion-item .accordion-body {
  font-size: 17px;
  font-weight: 600;
  color: var(--gray-01-color);
  line-height: 1.8;
}
@media screen and (max-width: 575px) {
  .accordion .accordion-header .accordion-button {
    font-size: 14px;
  }
  .accordion-item .accordion-body {
    font-size: 15px;
  }
}
