/* START info-details-bookings */
 .info-details-booking-one {
    background: var(--gray-04-color);
    border: var(--border-03);
    padding: 25px;
    border-radius: 12px;
    transition: all 0.5s;
    
  }
 .info-details-booking-one:hover {
    border-color: var(--main-01-color);
  }
.info-details-booking-one .num-booking {
    font-size: 22px;
    font-weight: 600;
    color: var(--dark-01-color);
  }
 .info-details-booking-one .text {
    font-size: 16px;
    font-weight: 600;
    color: var(--gray-01-color);
    padding-top: 10px !important;
  }
  /* END info-details-bookings */