.header-top-wallet {
  background: var(--main-01-color);
  border-radius: 16px;
  padding: 25px;
  width: fit-content;
}

.header-top-wallet .content-wallet-top :is(.title, .info-num) {
  font-size: 20px;
  font-weight: 600;
  color: var(--white-01-color);
}

.header-top-wallet .content-wallet-top :is(.info-num) {
  font-size: 25px;
  padding-top: 15px !important;
}

@media screen and (max-width: 991px) {
  .table-content-3 .table {
    width: 1200px !important;
  }
}
