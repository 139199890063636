.modal-custom .btn-close-icon {
  border: var(--border-04);
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: var(--dark-01-color);
  border-radius: 50%;
  transition: all 0.5s;
}
.modal-custom .btn-close-icon:hover {
  background: var(--main-01-color);
  color: var(--white-01-color);
}

.modal-custom .modal-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-01-color);
}
