.table-info-details-bookings .bg-main-color {
  background: var(--main-01-color);
}

.table-info-details-bookings .bg-main-color td {
  color: var(--white-01-color) !important;
}

.table-info-details-bookings .table-data-info .table {
  background: var(--white-02-color);
}
.table-info-details-bookings .table-data-info thead tr th {
  color: var(--dark-01-color) !important;
}

@media screen and (max-width: 437px) {
  .table-info-details-bookings .table-content-2 .table {
    width: 100% !important;
  }
  .table-info-details-bookings .main-btn {
    width: 100%;
  }
}

@media screen and (max-width: 340px) {
  .table-info-details-bookings .table-content-2 .table {
    width: 400px !important;
  }
}
.all-icons-info .menu-drop-1 .dropdown-menu.show {
  transform: translate(46px, 0px) !important;

}

