/* START HEADER BOOKING DETAILS */
.style-card-details {
  padding: 17px;
  border: var(--border-06);
  border-radius: 16px;
}
.header-info-booking-details .header-top-details .img-details img {
  width: 120px;
  height: 120px;
  border-radius: 16px;
}
.header-info-booking-details .header-top-details .right-details-one .title {
  font-size: 18px;
  font-weight: 500;
  color: var(--dark-01-color);
  padding-bottom: 12px !important;
}

.icon-star {
  font-size: 17px;
  color: var(--gray-01-color);
}
.text-size {
  font-size: 17px;
  color: var(--gray-01-color);
  font-weight: 500;
}
.text-size-02 {
  font-size: 17px;
  color: var(--dark-01-color);
  font-weight: 500;
}

.left-details-one .title {
  padding-bottom: 12px !important;
}
.info-employee .details-employee .name-employee {
  padding-bottom: 8px !important;
}
.info-employee .details-rate-employee {
  border: var(--border-06);
  border-radius: 50px;
  padding: 8px 16px;
}

.all-info-main-one {
  gap: 100px;
}
.comments-details .text {
  line-height: 1.8;
  padding-top: 12px !important;
}
/* END HEADER BOOKING DETAILS */
/* START reservation-info-booking */
.reservation-info-booking .title-header {
  border-bottom: var(--border-06);
  padding: 5px 0 20px 0 !important;
  font-size: 20px;
}

/* END reservation-info-booking */

/* SCREEN 991px */
@media screen and (max-width: 991px) {
  .header-info-booking-details .header-top-details .img-details img {
    width: 80px;
    height: 80px;
  }
  .header-info-booking-details .img-employee img {
    width: 55px !important;
    height: 55px !important;
  }
  .header-info-booking-details .header-top-details .right-details-one .title {
    font-size: 16px;
  }
  .icon-star {
    font-size: 15px;
  }
  .all-info-main-one {
    flex-wrap: wrap;
    gap: 15px;
  }
  .all-info-main-one .main-info-one {
    margin-left: 80px;
  }
}

/* SCREEN 767px */
@media screen and (max-width: 767px) {
  .header-info-booking-details .header-top-details .right-details-one .title {
    font-size: 25px;
  }

  .all-info-main-one {
    justify-content: flex-start;
    align-items: center;
  }
  .all-info-main-one .main-info-one {
    margin-left: 40px;
  }
}

/* SCREEN 575px */
@media screen and (max-width: 575px) {
  .text-size,
  .text-size-02 {
    font-size: 14px;
  }
  .header-info-booking-details .header-top-details .right-details-one .title {
    font-size: 15px;
    line-height: 1.8;
  }
}
/* SCREEN 493px */
@media screen and (max-width: 493px) {
  .all-info-main-one {
    column-gap: 50px;
  }
  .all-info-main-one .main-info-one {
    margin-left: 0px;
  }

  .reservation-info-booking .all-info-main-one {
    column-gap: 30px;
  }
}
