.discount-details .text-size {
  color: var(--red-dark-01-color);
  font-weight: 600;
}

.card-booking-coast .title-header .title {
  font-size: 22px;
  font-weight: 600;
  color: var(--main-01-color);
}
