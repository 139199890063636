.content-info-booking {
  border: var(--border-06);
  border-bottom: none;
  gap: 0 !important;
}

.content-info-booking .all-info-content .main-info-one {
  margin: 0 !important;
  border-bottom: var(--border-06);
  padding: 15px;
}

.invoice-content-info-print {
  background: var(--white-02-color);
  margin: 35px 0;
  padding: 25px;
  border-radius: 12px;
}
.invoice-content-info-print .title {
  font-size: 22px;
  font-weight: 600;
  color: var(--dark-01-color);
}
.invoice-content-info-print .image-print {
  margin: auto;
  width: fit-content;
  padding: 25px 0 10px 0;
}
.invoice-content-info-print .image-print img {
  height: 100%;
  object-fit: cover;
  width: 600px;
}

@media screen and (max-width: 700px) {
  .invoice-content-info-print .image-print img {
    width: 100%;
  }
}

/* START invoices-details-info */
.invoices-details-info {
  background: var(--white-01-color);
  border-radius: 12px;
}
.invoices-details-info .content-header-top{
  padding: 15px;

}
.invoices-details-info .content-invoice-header .title {
  font-size: 32px;
  font-weight: 600;
  color: var(--dark-01-color);
  padding-top: 15px !important;
}
.invoices-details-info .content-invoice-header :is(.text, .text-content) {
  font-size: 17px;
  font-weight: 600;
  color: var(--gray-01-color);
}
.table-invoice-content  tbody{
  background: var(--main-01-color);
}
.table-invoice-content .table-data-info td{
  color: var(--white-01-color) !important;

}
.table-invoice-content .bg-table-tr{
  background: #BE8C4D;
}
.table-invoice-content .table-data-info td:not(:last-child), 
.table-invoice-content .table-data-info th:not(:last-child)  
{
  border-left: var(--border-01);
  border-style: dashed;
 
}
.table-invoice-content .table-data-info .table :is(td, th){
  border-bottom: var(--border-01);
  border-style: dashed;
  text-align: center;
  
}
.table-all-invoice{
  width: 500px;
  margin-right: auto;
}

.invoices-details-info .footer-invoice{
  background: var(--main-01-color);
  padding: 12px;
  border-radius: 0 0 12px 12px;
 
}
.invoices-details-info .footer-invoice .text-link{ font-size: 17px;
  font-weight: 600;
  color: var(--white-01-color);
  text-align: center;
display: block;
}
@media screen and (max-width:767px) {
  .table-all-invoice{
    width: 100%;
  } 
}
@media screen and (max-width:600px) {
  .table-invoice-content .table-data-info .table{
    width: 150vw;

  }
  .table-invoice-content .table-data-info .table :is(td, th){
  font-size: 15px;
    
  }
  .table-all-invoice .table-striped>tbody>tr:nth-of-type(odd)>*{
    font-size: 13px;
  }
  .invoices-details-info .content-invoice-header .title{
    font-size: 24px;
    font-weight: 700;
  }
}

@media screen and (max-width:380px){
  .invoices-details-info .content-invoice-header :is(.text, .text-content){
    font-size: 12px;
    font-weight: 700;
  }

  .invoices-details-info .content-invoice-header .title{
    font-size: 18px;
    
  }
}
/* END invoices-details-info */
