.btn-scroll {
  position: fixed;
  bottom: 30px;
  right: -60px;
  transition: all 0.5s;
  z-index: 0;
  width: 40px;
  height: 40px;
  background: var(--main-01-color);
  font-size: 20px;
  color: var(--white-01-color);
  border: var(--border-06);
  border-radius: 8px;
  z-index: 120;
}
.btn-scroll.active {
  right: 30px;
}
