.modal-new-role .modal-footer .main-btn {
  padding: 8px 22px;
}

/* START PAGE DETAILS ROLE */
.all-details-roles .title-1 {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-01-color);
  line-height: 1.4 !important;
}
.all-details-roles .right-info-det .title-1 {
  font-size: 17px;
}
/* END PAGE DETAILS ROLE */
.all-edit-add-role {
  width: 100%;
  max-width: 600px;
}
@media screen and (max-width: 840px) {
  .all-edit-add-role {
    max-width: 100%;
  }
  .all-edit-add-role .content-role-update .main-info-edit {
    flex-wrap: wrap;
  }
}

.all-edit-add-role .content-role-update {
  border: 2px dashed var(--main-01-color);
  width: 100%;
  padding: 15px;
  border-radius: 16px;
}
.all-edit-add-role .content-role-update .icon-remove {
  width: 25px;
  height: 25px;
  background: var(--red-dark-01-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-01-color);
  cursor: pointer;
}
