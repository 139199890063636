.form-sign .all-form-sign {
  display: flex;
  align-items: center;
  min-height: calc(100vh - var(--bs-form-margin) * 2);
  --bs-form-margin: 1.75rem;
}

.form-sign .all-form-sign .main-form-sign {
  background: var(--white-01-color);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  padding: 35px;
  border-radius: 16px;
  width: 435px;
  margin: 60px auto 60px auto;
  border: var(--border-03);
  transition: all 0.5s;
}
.form-sign .all-form-sign .main-form-sign:hover {
  border-color: var(--main-01-color);
}

.form-sign .header-form-sign .title {
  font-size: 28px;
  font-weight: 600;
  color: var(--dark-01-color);
}

.form-sign .header-form-sign .text {
  font-size: 17px;
  font-weight: 500;
  color: var(--gray-01-color);
  padding-top: 10px !important;
}

.form-sign .font-main {
  font-size: 17px;
  font-weight: 600;
  color: var(--main-01-color);
  padding-top: 12px !important;
  display: block;
}
@media screen and (max-width: 480px) {
  .form-sign .all-form-sign .main-form-sign {
    width: 100%;
    padding: 35px 15px;
  }
  .form-sign .header-form-sign .title {
    font-size: 27px;
  }
}

.form-sign .btn-submit {
  background: var(--gray-03-color);
  border-color: var(--gray-03-color);
  transition: all 0.5s;
}
.form-sign .btn-submit:hover {
  background: var(--main-01-color);
  border-color: var(--main-01-color);
}
.footer-content .all-main-footer-content {
  height: 100%;
  padding: 15px;
  text-align: center;
  border-top: var(--border-06);
}
.footer-content .text-footer {
  font-size: 17px;
  font-weight: 600;
  color: var(--gray-01-color);
}

@media screen and (max-width: 360px) {
  .form-sign .header-form-sign .title {
    font-size: 22px;
    font-weight: 700;
  }
  .footer-content .text-footer {
    font-size: 14px;
  }
}

.margin-bottom-form .form-sign .all-form-sign .main-form-sign {
  margin: 60px auto 0px auto;
}
