.all-modal-reservation .title {
  font-size: 22px;
  font-weight: 600;
  color: var(--dark-01-color);
}

.all-modal-reservation .text {
  font-size: 16px;
  font-weight: 500;
  color: var(--gray-01-color);
  line-height: 1.8;
  padding-top: 12px !important;
}

.all-modal-evaluation .title {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-01-color);
}

.rate-employee-one .name-employee {
  font-size: 17px;
}
