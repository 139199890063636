.dropdown-1 {
  position: relative;
  display: inline-block;
  z-index: 111;
}

.dropdown-1 .img-user-nav {
  cursor: pointer;
  width: 40px;
  height: 40px;
}
.dropdown-1 .icon-notices-nav {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.dropdown-1 .dropdown-menu-1 {
  position: absolute;
  top: 100%;
  left: 160%;
  transform: translateX(-50%);
  background-color: var(--white-01-color);
  border: var(--border-03);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 5px 0;
  min-width: 150px;
  max-width: 300px;
  z-index: 1000;
}

.notices-drop-menu .dropdown-menu-1 {
  height: 60vh;
  overflow-y: auto;
}

.dropdown-1 .navbar-nav .nav-link {
  margin-left: 0 !important;
  padding-right: 15px;
}

/* width */
.notices-drop-menu .dropdown-menu-1::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.notices-drop-menu .dropdown-menu-1::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.notices-drop-menu .dropdown-menu-1::-webkit-scrollbar-thumb {
  background: var(--main-01-color);
}

/* Handle on hover */
.notices-drop-menu .dropdown-menu-1::-webkit-scrollbar-thumb:hover {
  background: var(--main-01-color);
}

.dropmenu-user-nav .dropdown-menu-1 {
  width: 150px;
}

.notices-drop-menu .dropdown-menu-1 {
  width: 400px;
  top: 53px;
}
.dropdown-1 .dropdown-menu-1 .nav-item .nav-link:hover {
  color: var(--main-01-color);
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .dropdown-1 .dropdown-menu-1 .navbar-nav .nav-link {
    padding-right: 12px !important;
  }
  .notices-drop-menu .dropdown-menu-1 {
    left: 130px;
  }
}

@media screen and (max-width: 450px) {
  .dropmenu-user-nav .dropdown-menu-1,
  .notices-drop-menu .dropdown-menu-1 {
    width: 95%;
    margin: auto;
    right: 0;
    left: 0;
    position: fixed;
    transform: translateX(0);
    top: 69px;
    border: var(--border-03);
  }
}
