.common-faq-dash .all-tabs-content .nav-tabs .nav-link{
    padding: 0 !important;
    height: 100px;
}



@media screen and (max-width:767px){
    .common-faq-dash .all-tabs-content .nav-tabs{
        flex-wrap: nowrap;
        overflow-x: auto;
    }    
    .common-faq-dash .all-tabs-content .nav-tabs .nav-link{
    width: 150px !important ;
        height: 100%;
        padding:15px 0 !important;
    }
    
}


@media screen and (max-width:450px){
  
    .common-faq-dash .all-tabs-content  .title-faq{
  flex-wrap: wrap;
    }
    
}