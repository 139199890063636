.size-text-color-dark {
  font-size: 17px;
  font-weight: 500;
  color: var(--dark-01-color);
}
.size-text-color-gray {
  font-size: 17px;
  font-weight: 500;
  color: var(--gray-01-color);
}

.red-color-text {
  color: var(--red-dark-01-color);
}

.main-text-color {
  color: var(--main-01-color);
}
.text-open {
  color: #34a853;
}

.all-services-name .main-header-services .link-user-route {
  color: var(--main-01-color);
  text-decoration: underline !important;
}

.all-services-name .main-header-services .title {
  font-size: 27px;
  padding-bottom: 15px !important;
}

@media screen and (max-width: 450px) {
  .all-services-name .main-header-services .title {
    font-size: 20px;
  }
}

.all-services-name .main-header-services .type-head {
  border: var(--border-06);
  padding: 4px 16px !important;
}

.card-serv-one {
  cursor: pointer;
}

.header-left .icon-one {
  width: 45px;
  height: 45px;
  border: var(--border-06);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 17px;
  color: var(--main-01-color);
  transition: all 0.4s;
  cursor: pointer;
}
.header-left .icon-one:hover,
.header-left .icon-one.active {
  background: var(--main-01-color);
  color: var(--white-01-color);
}

/* START SLIDER IMG */
.all-services-name .slider-card .img-slider img {
  height: 360px !important ;
  border-radius: 16px;
}

.all-services-name .slider-card .img-slider img {
  height: 360px !important ;
}

.all-services-name .slider-card .swiper-button-next {
  left: -55px;
}
.all-services-name .slider-card .swiper-button-prev {
  right: -55px;
}

.all-services-name .slider-card .swiper-button-next,
.all-services-name .slider-card .swiper-button-prev {
  transition: all 0.5s;
}

.all-services-name .slider-card:hover .swiper-button-next {
  left: 50px;
}

.all-services-name .slider-card:hover .swiper-button-prev {
  right: 50px;
}

@media screen and (max-width: 991px) {
  .all-services-name .slider-card:hover .swiper-button-next {
    left: 30px;
  }

  .all-services-name .slider-card:hover .swiper-button-prev {
    right: 30px;
  }
}

.all-services-name .slider-card .button-show {
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 1;
}

.all-services-name .slider-card .button-show .main-btn {
  background: #d9d9d9;
  border-color: #d9d9d9;
  color: var(--dark-01-color);
}
/* END SLIDER IMG */
/* START COST INFO */
.cost-details .title-info {
  font-size: 20px;
}
.cost-details .icon-star {
  font-size: 14px;
}
.cost-details .all-rates {
  font-size: 15px;
}
/* START COST INFO */
/* START SERVICESFILTER NAME */
.all-services-name .main-buttons-serv {
  padding: 25px 0;
}
.all-services-name .btn-filter-one {
  padding: 9px 16px;
  font-size: 14px;
  max-width: 100%;
  min-width: fit-content;
}
.main-buttons-serv .owl-carousel {
  position: relative;
  border-bottom: var(--border-06);
  padding-bottom: 5px;
}
.main-buttons-serv .owl-nav {
  width: fit-content;
  background: var(--white-01-color);
  height: 50px;
  padding: 0 20px;
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  margin-top: 0 !important;
  display: flex;
  gap: 15px;
  align-items: center;
}
.main-buttons-serv .owl-nav :is(.owl-prev, .owl-next) {
  font-size: 35px !important;
  margin: 0 5px !important;
  margin-top: -9px !important;
}
.main-buttons-serv .owl-theme .owl-nav [class*="owl-"]:hover {
  background: transparent;
  color: var(--main-01-color);
}
.main-buttons-serv .btn-filter-one.active {
  background: var(--main-01-color);
  color: var(--white-01-color);
}
@media screen and (max-width: 1199px) {
  .main-buttons-serv .btn-filter-one {
    padding: 9px auto;
    font-size: 14px;
    max-width: 100%;
    min-width: 100%;
    width: 100%;
  }
}

.icon-plus {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ebecec;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s;
}

.icon-plus.active {
  background: var(--main-01-color);
  color: var(--white-01-color);
}
/* END SERVICESFILTER NAME */
/* START BANNER SERV */
.all-banner-serv .info-content-one {
  border: var(--border-05);
  padding: 10px;
  width: 100%;
}
.all-banner-serv .info-content-one .name-day {
  color: var(--white-02-color);
}
.all-banner-serv .all-info-bottom {
  border-color: var(--gray-02-color);
  padding: 30px;
  margin-top: 10px;
}
.all-banner-serv .map-info iframe {
  border-radius: 0 !important;
}
.all-banner-serv .banner-home {
  height: 600px;
}

@media screen and (max-width: 1089px) {
  .all-banner-serv .info-content-one {
    width: 100%;
  }
  .all-banner-serv .info-content-one .size-text-color-gray {
    font-size: 14px;
  }
}
@media screen and (max-width: 991px) {
  .all-banner-serv .banner-home {
    height: 100%;
  }
}
@media screen and (max-width: 850px) {
  .all-banner-serv .info-content-one .size-text-color-gray {
    font-size: 13px;
  }
}
@media screen and (max-width: 575px) {
  .all-banner-serv .info-content-one .size-text-color-gray {
    font-size: 16px;
  }
  .content-info-serv .all-content-info .title {
    font-size: 26px !important;
  }
  .content-info-serv .all-content-info .text {
    font-size: 15px;
    font-weight: 600 !important;
  }
}
@media screen and (max-width: 345px) {
  .all-banner-serv .info-content-one .size-text-color-gray {
    font-size: 14px;
  }
}
/* END BANNER SERV */

.vendor-socials {
  margin: 20px 0;
  padding: 20px;
  background-color: var(--white-01-color);
  border: var(--border-06);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.vendor-socials h3 {
  color: var(--dark-01-color);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.vendor-socials .list-social {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

.vendor-socials .list-one {
  margin: 0;
}

.vendor-socials .icon-social {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  background-color: var(--white-01-color);
  border: var(--border-06);
  border-radius: 50%;
  color: var(--main-01-color);
  font-size: 18px;
  transition: all 0.3s ease;
  text-decoration: none;
}

.vendor-socials .icon-social:hover {
  background-color: var(--main-01-color);
  color: var(--white-01-color);
}

@media screen and (max-width: 575px) {
  .vendor-socials {
    padding: 15px;
  }

  .vendor-socials h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .vendor-socials .icon-social {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
}
