.bg-body {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
