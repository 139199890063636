.btn-apple {
  background: var(--dark-04-color);
  border-color: var(--dark-04-color);
}

.btn-face {
  background: #0c82ee;
  border-color: #0c82ee;
}

@media screen and (max-width: 360px) {
  .all-main-new-register .btn-register {
    font-size: 14px;
  }
}

@media screen and (max-width: 325px) {
  .all-main-new-register .btn-register {
    font-size: 13px;
  }
}
